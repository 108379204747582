import React, { Fragment, useEffect, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  OutlinedInput,
  Chip,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshingIndicator from "../components/RefreshingIndicator";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import RenderOnRole from "./RenderOnRole";
import UserService from "../services/UserService";
import MarkAsLostRequestCardsDialog from "./MarkAsLostRequestCardsDialog";
import DownloadIcon from "@mui/icons-material/Download";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AvailableCards(props) {
  const navigate = useNavigate();
  const {
    isLoading,
    isFetching,
    isError,
    data,
    dataLocations,
    isLoadingLocations,
    refetchFunction,
    refetchStatsFunction,
    setPageFunction,
    setFilterStringFunction,
    dataCount,
    isLoadingDataCount,
    dataPayCardAsReplacement,
    isLoadingPayCardAsReplacement,
    pageSize,
    onPageSizeChange,
    setLocationIdsFunction,
  } = props;

  const theme = useTheme();

  const [elements, setElements] = React.useState([]);

  const [select, setSelection] = useState([]);
  const [locationIds, setLocationIds] = React.useState({});

  const [selectedLocation, setSelectedLocation] = useState(0);

  const [openMarkAsLostDlg, setOpenMarkAsLostDlg] = useState(false);
  const [infoToMarkAsLost, setInfoToMarkAsLost] = useState({});

  const [filter, setFilter] = useState("");

  const onFilterChange = (filter, params) => {
    const filterString = filter.quickFilterValues.join(" ");
    if (filterString === undefined) {
      setFilterStringFunction("");
      setFilter("");
    } else {
      setFilterStringFunction(filterString);
      setFilter(filterString);
    }
    return filter;
  };

  const onAssignButtonClick = (e, params) => {
    navigate("/assignCard/" + params.id);
  };

  const clearSelectionsAndRefreshData = () => {
    setSelectedLocation("");
    setSelection([]);
    refetchFunction();
    refetchStatsFunction();
    setFeedback({ open: false });
  };

  const changeLocationMutation = useMutation(
    (payCards) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/location`,
        { cards: payCards, location: payCards.location }
      );
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "cards location changed succesfully",
          onClose: () => {
            clearSelectionsAndRefreshData();
          },
        });
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onTransferButtonClick = (e, params, changeLocationMutation) => {
    let paycards = params;
    paycards.location = selectedLocation;
    changeLocationMutation.mutateAsync(paycards);
  };

  const onMarkAsLostButtonClick = (e, params) => {
    let paycards = params;
    setInfoToMarkAsLost(paycards);
    setOpenMarkAsLostDlg(true);
  };

  const onClearSelectionButtonClick = (e, params) => {
    setSelection([]);
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  var dataColumns = [
    { field: "id", headerName: "ID", width: 90, hidden: true, sortable: false },
    {
      field: "cardNumber",
      headerName: "Card Last 4",
      width: 120,
      sortable: false,
      hidden: dataPayCardAsReplacement?.data?.value === "true",
    },
    {
      field: "embossing",
      headerName: "Embossing Line",
      width: 260,
      sortable: false,
    },
    {
      field: "cardProxy",
      headerName: "Proxy",
      width: 150,
      sortable: false,
      hidden: dataPayCardAsReplacement?.data?.value === "true",
    },
    {
      field: "locationDescription",
      headerName: "Location",
      width: 220,
      sortable: false,
    },
    { field: "status", headerName: "Status", width: 220, sortable: false },
    {
      field: "Action",
      headerName: "Action",
      width: 110,
      sortable: false,
      renderCell: (params) => {
        if (!select || select.length === 0)
          return (
            // you will find row info in params
            <RenderOnRole roles={["pc-assign-employee"]}>
              <Button
                onClick={(e) => onAssignButtonClick(e, params.row)}
                variant="contained"
                sx={{ textTransform: "none", fontSize: "1 rem" }}
              >
                <AssignmentIndIcon />
                Assign
              </Button>
            </RenderOnRole>
          );
        else return "";
      },
    },
    {
      field: "updateDate",
      headerName: "Update Date",
      width: 190,
      sortable: false,
    },
  ];

  const handleRowSelection = (rows) => {
    setSelection(rows);
  };

  const handleChange = (event, location) => {
    setSelectedLocation(location.props.value);
  };

  const handleChangeElements = (event) => {
    const {
      target: { value },
    } = event;
    setLocationIdsFunction(value);
    setLocationIds(value);
    //refetchFunction();
    setElements(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const downloadAvailableMutation = useMutation(
    (params) => {
      const locations =
        Array.from(locationIds).length > 0
          ? Array.from(locationIds).join(",")
          : null;

      return HttpService.getAxiosClient().get(
        window.API_URL +
          `/paycards/available/export?filter=` +
          filter +
          (locations !== null ? `&locations=` + locations : ""),
        { responseType: "blob" }
      );
    },
    {
      onSuccess: (response) => {
        const { headers, data } = response;
        const disposition = headers["content-disposition"];
        let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace("utf-8''", ""));
        else filename = filename.replace(/['"]/g, "");
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element
        setFeedback({
          open: true,
          type: "success",
          message: "The file was download succesfully",
          onClose: () => {
            setFeedback({ open: false });
          },
        });
      },
      onError: (err) => {
        if (err?.response?.status === 500) {
          setFeedback({
            open: true,
            type: "error",
            message: "There was an error trying to download the file",
          });
        } else {
          const msg = err?.response?.data ? err.response.data : err.message;
          setFeedback({
            open: true,
            type: "error",
            message: msg,
          });
        }
      },
    }
  );

  const onDownloadDetailsButtonClick = (e, params) => {
    downloadAvailableMutation.mutate(null);
  };

  useEffect(() => {}, [select]);

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "20px" }}>
          <h1>Paycards available</h1>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={{ margin: "10px", height: "40px" }}
              onClick={onDownloadDetailsButtonClick}
              variant="contained"
              disabled={downloadAvailableMutation.isLoading}
              color={
                downloadAvailableMutation.isLoading ? "secondary" : "primary"
              }
            >
              <DownloadIcon style={{ paddingRight: "5px" }} />
              {downloadAvailableMutation.isLoading
                ? "Downloading..."
                : "Export Available Paycards"}
            </Button>
          </div>
        </div>
        {isFetching ? <RefreshingIndicator /> : ""}
      </div>

      {isLoading || isLoadingDataCount || isLoadingPayCardAsReplacement ? (
        <CircularProgress color="inherit" />
      ) : !isError ? (
        <div style={{ height: 450, width: "100%" }}>
          <RenderOnRole roles={["pc-reassign-location"]}>
            {select &&
            select.length > 0 &&
            !isLoadingLocations &&
            dataLocations &&
            dataLocations.data.length > 0 &&
            !isLoadingPayCardAsReplacement &&
            dataPayCardAsReplacement ? (
              <Fragment>
                <FormControl style={{ width: "200px", height: "80px" }}>
                  <InputLabel id="simple-select-label">Location </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    label="Location"
                    onChange={handleChange}
                    value={selectedLocation}
                  >
                    <MenuItem value={0}>-</MenuItem>
                    {dataLocations?.data.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  style={{ margin: "10px" }}
                  onClick={(e) =>
                    onTransferButtonClick(e, select, changeLocationMutation)
                  }
                  variant="contained"
                  disabled={
                    selectedLocation === 0 ||
                    changeLocationMutation.isLoading ||
                    feedback.open
                  }
                >
                  <EditLocationIcon />
                  <span style={{ paddingLeft: "10px" }}>
                    {!changeLocationMutation.isLoading
                      ? " Change Location"
                      : "Processing..."}
                  </span>
                </Button>
                <Button
                  color="warning"
                  style={{ margin: "10px" }}
                  onClick={(e) => onMarkAsLostButtonClick(e, select)}
                  variant="contained"
                  disabled={feedback.open}
                >
                  <CreditCardOffIcon />
                  <span style={{ paddingLeft: "10px" }}>
                    {" REPORT AS LOST"}
                  </span>
                </Button>
                <Button
                  color="primary"
                  style={{ marginLeft: "40px", margin: "10px" }}
                  onClick={(e) => onClearSelectionButtonClick(e, select)}
                  variant="contained"
                  disabled={feedback.open}
                >
                  <IndeterminateCheckBoxIcon />
                  <span style={{ paddingLeft: "10px" }}>
                    {" Clear the selection"}
                  </span>
                </Button>
              </Fragment>
            ) : (
              <div style={{ padding: "20px" }}>
                <span>Click on the checkboxes to change the location </span>
                <NotListedLocationIcon color="primary" />
                <span> or report as lost </span>
                <CreditCardOffIcon color="warning" />
              </div>
            )}
          </RenderOnRole>
          <div
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                width: 500,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <InputLabel id="demo-multiple-chip-label">Locations</InputLabel>
              <Select
                //disabled={disableGroupOptions}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={elements}
                onChange={handleChangeElements}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="LocationsChip"
                  />
                }
                renderValue={(selected, other) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((key) => {
                      return (
                        <Chip
                          key={key}
                          label={
                            dataLocations?.data.find((item) => item.id === key)
                              .description
                          }
                        />
                      );
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {dataLocations?.data?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.description, elements, theme)}
                  >
                    {option.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <DataGrid
            checkboxSelection={UserService.hasRole(["pc-reassign-location"])}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            columnVisibilityModel={{ id: false }}
            onSelectionModelChange={handleRowSelection}
            onPageSizeChange={onPageSizeChange}
            selectionModel={select}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 800 },
              },
            }}
            rows={data?.data}
            columns={[
              {
                ...GRID_CHECKBOX_SELECTION_COL_DEF,
                headerName: "Change Location",
                width: 50,
              },
              ...dataColumns.filter((item) => item.hidden !== true),
            ]}
            rowCount={dataCount?.data}
            onPageChange={(newPage) => {
              setPageFunction(newPage);
            }}
            paginationMode="server"
            filterMode="server"
            onFilterModelChange={onFilterChange}
            keepNonExistentRowsSelected
            sx={{
              height: "calc(55vh)",
              "& .MuiButton-text ": { display: "none" },
              "& .MuiDataGrid-cellContent": {
                fontFamily: "Helvetica",
                fontSize: "1rem",
              },
            }}
          />
          <Snackbar
            open={feedback.open}
            autoHideDuration={6000}
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={
                feedback.onClose ? feedback.onClose : handleFeedbackClose
              }
              severity={feedback.type}
              sx={{ width: "100%" }}
            >
              {feedback.message}
            </Alert>
          </Snackbar>
          {openMarkAsLostDlg ? (
            <MarkAsLostRequestCardsDialog
              action="REPORT AS LOST"
              open={openMarkAsLostDlg}
              setOpen={setOpenMarkAsLostDlg}
              dialogTitle="Report As Lost Request"
              dialogContentText={
                "Are you sure you want to report the selected " +
                infoToMarkAsLost.length +
                " card(s) as lost?, Please type the reason and click REPORT AS LOST button."
              }
              infoToProcess={infoToMarkAsLost}
              clearFunction={clearSelectionsAndRefreshData}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <h4>An error ocurred, please try again later...</h4>
      )}
    </Fragment>
  );
}
