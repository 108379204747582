import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";
import { useState } from "react";

const generateDataset = (type) => {
  console.log(type);
  if (type === "year") {
    return [
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Jan" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Feb" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Mar" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Apr" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "May" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "June" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "July" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Aug" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Sept" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Oct" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Nov" },
    ];
  }
  if (type === "month") {
    return [
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Week 1" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Week 2" },
      { seoul: Math.floor(Math.random() * 101 + 1), month: "Week 3" },
    ];
  }
  if (type === "today") {
    return [
      { seoul: Math.floor(Math.random() * 10 + 1), month: "00" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "01" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "02" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "03" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "04" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "05" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "06" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "07" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "08" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "09" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "10" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "11" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "12" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "13" },
      { seoul: Math.floor(Math.random() * 10 + 1), month: "14" },
    ];
  }
  return [{ seoul: Math.floor(Math.random() * 101 + 1), month: "Jan" }];
};

export function valueFormatter(value) {
  return `${value}mm`;
}

const chartSetting = {
  width: 440,
  height: 300,
};
const lineChartSetting = {
  width: 600,
  height: 300,
};

export default function CardsDashboard() {
  const [dataset, setDataset] = useState(generateDataset("year"));

  return (
    <Container maxWidth="xlg">
      <Accordion
        defaultExpanded={true}
        sx={{ backgroundColor: "rgba(218, 218, 218, 0.25)" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Cards Dashboard
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card style={{ width: "30%", padding: 8 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date Range:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Date Range"
                    onChange={(value) =>
                      setDataset(generateDataset(value.target.value))
                    }
                  >
                    <MenuItem value={"year"}>This Year</MenuItem>
                    <MenuItem value={"month"}>This Month</MenuItem>
                    <MenuItem value={"today"}>Today</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Cards Distributed</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <BarChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[{ dataKey: "seoul", valueFormatter }]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Cards Activated</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <BarChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[{ dataKey: "seoul", valueFormatter }]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Unactivated Cards</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <BarChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[{ dataKey: "seoul", valueFormatter }]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "rgba(218, 218, 218, 0.25)" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Disbursed funds
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card style={{ width: "30%", padding: 8 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date Range:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Date Range"
                    onChange={(value) =>
                      setDataset(generateDataset(value.target.value))
                    }
                  >
                    <MenuItem value={"year"}>This Year</MenuItem>
                    <MenuItem value={"month"}>This Month</MenuItem>
                    <MenuItem value={"today"}>Today</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">USD Disbursed</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <LineChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[{ dataKey: "seoul", valueFormatter }]}
                      {...lineChartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Cards Disbursed</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <LineChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[{ dataKey: "seoul", valueFormatter }]}
                      {...lineChartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "rgba(218, 218, 218, 0.25)" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Employer balance
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card style={{ width: "30%", padding: 8 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date Range:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Date Range"
                    onChange={(value) =>
                      setDataset(generateDataset(value.target.value))
                    }
                  >
                    <MenuItem value={"year"}>This Year</MenuItem>
                    <MenuItem value={"month"}>This Month</MenuItem>
                    <MenuItem value={"today"}>Today</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Max Balance</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <LineChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[
                        {
                          dataKey: "seoul",
                          valueFormatter,
                          area: true,
                          showMark: false,
                        },
                      ]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Avg Balance</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <LineChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[
                        {
                          dataKey: "seoul",
                          valueFormatter,
                          area: true,
                          showMark: false,
                        },
                      ]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <Grid container padding={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="body">Minimun Balance</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <LineChart
                      dataset={dataset}
                      xAxis={[{ scaleType: "band", dataKey: "month" }]}
                      series={[
                        {
                          dataKey: "seoul",
                          valueFormatter,
                          area: true,
                          showMark: false,
                        },
                      ]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
