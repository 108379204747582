import React, { Fragment } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import BlockIcon from "@mui/icons-material/Clear";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DownloadIcon from "@mui/icons-material/Download";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CheckIcon from "@mui/icons-material/Check";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Button, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import { MonetizationOn, PlayCircle } from "@mui/icons-material";

export default function ButtonWithIcon(props) {
  const {
    hidden,
    onClick,
    record,
    color,
    text,
    tooltip,
    icon,
    positionTextAtRight,
    isLoading,
    disabled,
  } = props;

  const renderIcon = (icon) => {
    switch (icon) {
      case "download":
        return (
          <DownloadIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "approve-card":
        return (
          <CreditScoreIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "add-card":
        return (
          <AddCardIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "deny":
        return (
          <BlockIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "confirm":
        return (
          <CheckIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "add-file":
        return (
          <PostAddIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "view":
        return (
          <VisibilityIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "edit":
        return (
          <EditIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "details":
        return (
          <FormatAlignJustifyIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "stats":
        return (
          <QueryStatsIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "delete":
        return (
          <DeleteIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "process":
        return (
          <DoneIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "play":
        return (
          <PlayCircle
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
      case "money":
        return (
          <MonetizationOn
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );

      default:
        return (
          <ThumbUpAltIcon
            style={{
              marginLeft: text !== null && text !== undefined ? "5px" : 0,
            }}
            variant="contained"
          />
        );
    }
  };

  const renderButtonWithTextAtLeft = (icon) => {
    return (
      <Fragment>
        {text !== null && text !== undefined ? (
          <span style={{ fontSize: "0.8rem", minWidth: "70px" }}>{text}</span>
        ) : (
          ""
        )}
        <Tooltip title={tooltip && tooltip}>
          {isLoading ? (
            <CircularProgress size="1.5rem" style={{ paddingRight: "1px" }} />
          ) : (
            renderIcon(icon)
          )}
        </Tooltip>
      </Fragment>
    );
  };

  const renderButtonWithTextAtRight = (icon) => {
    return (
      <Fragment>
        <Tooltip title={tooltip && tooltip}>
          {isLoading ? (
            <CircularProgress size="1.5rem" style={{ paddingRight: "1px" }} />
          ) : (
            renderIcon(icon)
          )}
        </Tooltip>
        {text !== null && text !== undefined ? (
          <span
            style={{ marginLeft: "5px", fontSize: "0.8rem", minWidth: "70px" }}
          >
            {text}
          </span>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  return (
    <>
      {!hidden && (
        <Button
          disabled={disabled}
          onClick={(e) => onClick(e, record)}
          style={{ minWidth: "10px", color: color }}
        >
          {positionTextAtRight
            ? renderButtonWithTextAtRight(icon)
            : renderButtonWithTextAtLeft(icon)}
        </Button>
      )}
    </>
  );
}
