import { useEffect, useState } from "react";

function CardImage() {
  const [cardImage, setCardImage] = useState(null);

  useEffect(() => {
    async function getInitials() {
      try {
        const [cardImage] = await Promise.all([
          // import(
          //   `../images/cards/card.${window.subdomain}.${cardService.cardDetail.brand}.${cardService.cardDetail.shortLabel}.svg`
          // ),
          import(`../images/cards/card.rv.mastercard.PAYCARD.svg`),
        ]);
        setCardImage(cardImage.default);
      } catch (error) {
        try {
          const [cardImage] = await Promise.all([
            import(`../images/cards/card.svg`),
          ]);
          setCardImage(cardImage.default);
        } catch (error) {
          console.error("Error loading card image:", error);
        }
      }
    }
    getInitials();
  }, []);

  return cardImage;
}

export default CardImage;
