import React from "react";

import RellevateMenu from "../components/Menu";
import Footer from "../components/Footer";
import Home from "./Home";
import backgroundImg from "../images/Orange-to-green-background.png";

import { Route, Routes } from "react-router-dom";
import QueryAssignedPaycards from "./QueryAssignedPaycards";
import QueryAvailablePaycards from "./QueryAvailablePaycards";

import AssignPaycard from "./AssignPaycard";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Box } from "@mui/system";
import Locations from "./Locations";
import Bundles from "./Bundles";
import QueryPayments from "./QueryPayments";
import ManageBalance from "./ManageBalance";
import QueryBalance from "./QueryBalance";
import QueryPendingPayments from "./QueryPendingPayments";
import QueryTimeAttendance from "./QueryTimeAttendance";
import QueryMembers from "./QueryMembers";
import QueryMissingTimeAttendance from "./QueryMissingTimeAttendance";
import UploadCipFile from "./UploadCipFile";
import Onboardings from "./Onboardings";
import QueryPaymentsSummary from "./QueryPaymentsSummary";
import UploadEwaCollectionAttempts from "./UploadEwaCollectionAttempts";
import StartedOnboardings from "./StartedOnboardings";
import EmployerAttributesConfig from "./EmployerAttributesConfig";
import ManageGroups from "./ManageGroups";
import CampaingsConfig from "./CampaignsConfig";
import BulkOnboarding from "./BulkOnboarding";
import DashboardLocations from "./DashboardLocations";
import QueryMultiBalance from "./QueryMultiBalance";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import QueryCompanyMembers from "./QueryCompanyMembers";
import ManageWhitelist from "./ManageWhitelist";
import StartedOnboardingsRds from "./StartedOnboardingsRds";
import FlashReports from "./FlashReports";
import CardsDashboard from "./CardsDashboard";

export default function MainSite() {
  async function fetchEmployerInfo() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer`
    );
    return response;
  }

  const {
    data: dataEmployer,
    isLoading: isFetchingEmployer,
    isError: isErrorEmployer,
  } = useQuery(["employerInfo"], fetchEmployerInfo);

  if (isFetchingEmployer) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <>
      <Box
        overflow="auto"
        height="100vh"
        flexDirection="column"
        display="flex"
        sx={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <CssBaseline />
        <Box>
          <RellevateMenu />
        </Box>
        <Box
          overflow="auto"
          flex={1}
          flexDirection="column"
          display="flex"
          p={2}
        >
          <Routes>
            {/* PayCard*/}
            <Route exact path="/" element={<Home />} />
            <Route exact path="/assignCard" element={<AssignPaycard />} />
            <Route
              exact
              path="/assignCard/:bundleDetailId"
              element={<AssignPaycard />}
            />
            <Route
              exact
              path="/queryAssignedPaycards"
              element={
                <QueryAssignedPaycards
                  employerIdentifier={dataEmployer?.data?.idEmployer}
                />
              }
            />
            <Route
              exact
              path="/queryAvailablePaycards"
              element={<QueryAvailablePaycards />}
            />
            <Route
              exact
              path="/requestPaycards"
              element={
                <Bundles employerIdentifier={dataEmployer?.data?.idEmployer} />
              }
            />
            <Route exact path="/locations" element={<Locations />} />
            <Route
              exact
              path="/dashboardLocations"
              element={<DashboardLocations />}
            />
            <Route exact path="/cip" element={<UploadCipFile />} />
            <Route
              exact
              path="/ewa/collectionAttempts"
              element={<UploadEwaCollectionAttempts />}
            />
            {/* Payfile*/}
            <Route exact path="/manageBalance" element={<ManageBalance />} />
            <Route exact path="/balance" element={<QueryBalance />} />
            <Route exact path="/multiBalance" element={<QueryMultiBalance />} />
            <Route
              exact
              path="/payments/summary"
              element={
                <QueryPaymentsSummary
                  employerIdentifier={dataEmployer?.data?.idEmployer}
                />
              }
            />
            <Route exact path="/payments" element={<QueryPayments />} />
            <Route
              exact
              path="/pendingPayments"
              element={<QueryPendingPayments />}
            />
            <Route exact path="/whitelist" element={<ManageWhitelist />} />
            {/* PayAnyDay*/}
            <Route
              exact
              path="/timeAttendance"
              element={<QueryTimeAttendance />}
            />
            <Route
              exact
              path="/companyMembers"
              element={<QueryCompanyMembers />}
            />
            <Route exact path="/members" element={<QueryMembers />} />
            <Route
              exact
              path="/timeAttendance/missing"
              element={<QueryMissingTimeAttendance />}
            />
            {/* Onboarding*/}
            <Route exact path="/onboardings" element={<Onboardings />} />
            <Route
              exact
              path="/onboardings/started"
              element={<StartedOnboardings />}
            />
            <Route
              exact
              path="/onboardings/started/rds"
              element={<StartedOnboardingsRds />}
            />
            <Route
              exact
              path="/onboardings/bulk"
              element={<BulkOnboarding />}
            />
            {/* Configure*/}
            <Route
              exact
              path="/employerAttr"
              element={<EmployerAttributesConfig />}
            />
            <Route
              exact
              path="/campaignsConfig"
              element={<CampaingsConfig />}
            />
            <Route exact path="/flashReports" element={<FlashReports />} />
            <Route exact path="/cardsDashboard" element={<CardsDashboard />} />
            {/* Groups*/}
            <Route exact path="/manageGroup" element={<ManageGroups />} />
            manageGroups
            <Route exact path="*" element={<div>Under construction...</div>} />
          </Routes>
        </Box>
        <Box p={2.3}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
