import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useState } from "react";

export default function Main({ bundle }) {
  async function fetchBundleDetailData(inputValue) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/bundleDetail?bundleId=${inputValue}`
    );
    return response;
  }

  const { data, isLoading } = useQuery(["bundleDetail", bundle.id], () =>
    fetchBundleDetailData(bundle.id)
  );

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    //props.setOpen(false);
  };

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const handleSuccess = () => {
    //refetch();
    setFeedback({
      open: true,
      type: "success",
      message: "The request was sent",
      onClose: () => {
        //clearFunction();
        setFeedback({ open: false });
        handleClose();
      },
    });
  };

  const resendEmailForVirtualCardMutation = useMutation(
    (request) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/` + request.id + `/resend`
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onResendEmailButtonClick = (e, params) => {
    resendEmailForVirtualCardMutation.mutate({ id: params.id });
  };

  const columns = [
    { field: "id", headerName: "id", hide: true },
    { field: "cardType", headerName: "Card Type", width: 170 },
    { field: "employeeNo", headerName: "Identifier", width: 100 },
    { field: "email", headerName: "User", width: 230 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "action",
      width: 80,
      headerName: "Action",
      renderCell: (params) => {
        return (
          // you will find row info in params
          <div
            style={{
              display:
                params.row.status === "Card Issued" ||
                params.row.status === "ACTIVE"
                  ? "block"
                  : "none",
            }}
          >
            <Tooltip title={`Resend the virtual card email `}>
              <Button
                style={{ minWidth: "10px" }}
                disabled={resendEmailForVirtualCardMutation.isLoading}
              >
                <ForwardToInboxIcon
                  onClick={(e) => onResendEmailButtonClick(e, params.row)}
                  variant="contained"
                />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    { field: "extraInfo01", headerName: "Extra Info 01", width: 250 },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "descriptor", headerName: "Descriptor", width: 250 },
    { field: "error", headerName: "Error", width: 450 },
  ];

  return (
    <Container maxWidth="xlg">
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Bundle ID:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {bundle.id}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Last updated:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {bundle.updateDate}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Status:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {bundle.status}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Location:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {bundle.idLocation}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Cards count:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {bundle.quantity}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Total Amount:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(bundle.totalAmount)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Description:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {bundle.description}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ height: "70vh" }} mt={2}>
          {!!!isLoading ? (
            <DataGrid
              density="compact"
              rowSelection={false}
              rows={data?.data}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100]}
              //autoPageSize={true}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              sx={{
                "& .MuiDataGrid-cell": {
                  fontSize: "0.9rem",
                },
              }}
            />
          ) : (
            <div>Loading...</div>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
