import {
  Alert,
    Box,
    Button,
    Grid,
    Snackbar,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { useMutation, useQueryClient } from "@tanstack/react-query";
  import HttpService from "../services/HttpService";
import LoadingButton from "./LoadingButton";
  
  export default function ModifyCompanyMember(props) {
    const queryClient = useQueryClient();
    const [newEmail, setNewEmail] = React.useState("");
    const [feedback, setFeedback] = React.useState({
      open: false,
      type: "error",
      message: "",
    });
  
    const executeMutation = useMutation(
      (data) => {
        return HttpService.getAxiosClient().post(
          window.API_URL + `/member/changeEmail`,
          data
        );
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["members"]);
          props.handleClose();
        },
        onError: (err) => {
          setFeedback({
            open: true,
            type: "error",
            message: err?.response?.data?.result || "An error occurred",
          });
        },
      }
    );
  
    const changeEmailMember = (event) => {
      if (newEmail === "") {
        return;
      }
      const data = {
        customerId: props.memberInfo.idCustomer,
        newEmail: newEmail
      };
      executeMutation.mutate(data);
    };
  
    return (
      <div>
        <Box component="form" noValidate autoComplete="off">
            <Grid container>
              <Grid item xs={12} p={1}>
                <TextField
                  fullWidth
                  label="Current email"
                  value={props.memberInfo.email}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} p={1}>
                  <TextField
                    fullWidth
                    label="New Username/email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    error={newEmail === ""}
                    helperText={newEmail === "" ? "This field is required" : ""}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} pt={3} pr={1}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={changeEmailMember}
                  loading={executeMutation.isLoading}
                  >
                  SAVE
                </LoadingButton>
              </Grid>
              <Grid item xs={6} pt={3} pl={1}>
                <Button fullWidth variant="contained" color="secondary" onClick={props.handleClose}>
                  CLOSE
                </Button>
              </Grid>
            </Grid>
        </Box>
        <Snackbar
          open={feedback.open}
          autoHideDuration={6000}
          onClose={() => setFeedback({ ...feedback, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setFeedback({ ...feedback, open: false })}
            severity={feedback.type}
            sx={{ width: "100%" }}
          >
            {feedback.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
  