import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, forwardRef } from "react";
import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CircularProgress from "@mui/material/CircularProgress";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PanView from "./PanView";

export default function SecureInfoCardDetail(props) {
  const { idBundleDetail } = props;

  async function fetchPaycardDetail() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/` + idBundleDetail
    );
    return response;
  }

  const { isError: isErrorPaycardDetail, isFetching: isFetchingPayCardDetail } =
    useQuery({
      queryKey: ["paycardDetail"],
      queryFn: fetchPaycardDetail,
      refetchOnWindowFocus: false,
    });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
    >
      <Box sx={{ width: "600px", height: "600px" }}>
        {isFetchingPayCardDetail ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : isErrorPaycardDetail ? (
          <h4>There was an error getting the info</h4>
        ) : (
          <>
            <DialogTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {props.dialogTitle}
                <CreditCardIcon
                  style={{ marginLeft: "20px", marginRight: "10px" }}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <br />

              <Grid container spacing={1.5}>
                <Grid container spacing={1.5}>
                  <PanView
                    onTimeout={props.setOpen}
                    memberId={props.memberId}
                    companyId={props.companyId}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color={"error"}>
                {false ? <CircularProgress color="inherit" /> : "Close"}
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
