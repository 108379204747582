import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState, forwardRef } from "react";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useFormik } from "formik";
import * as yup from "yup";
import throttle from "lodash/throttle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { hasValue } from "../util/string";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import RenderOnRole from "./RenderOnRole";

const autocompleteService = { current: null };

export default function OnboardingDetail(props) {
  const [options, setOptions] = useState([]);

  const [value, setValue] = useState(null);

  const [inputValue, setInputValue] = useState("");

  const [physicalAddressLine1, setPhysicalAddressLine1] = useState("");
  const [physicalAddressLine2, setPhysicalAddressLine2] = useState("");
  const [physicalCity, setPhysicalCity] = useState("");
  const [physicalState, setPhysicalState] = useState("");
  const [physicalZipCode, setPhysicalZipCode] = useState("");

  const [mailingAddressLine1, setMailingAddressLine1] = useState("");
  const [mailingAddressLine2, setMailingAddressLine2] = useState("");
  const [mailingCity, setMailingCity] = useState("");
  const [mailingState, setMailingState] = useState("");
  const [mailingZipCode, setMailingZipCode] = useState("");

  const [editMode, setEditMode] = useState(true);
  const [createdOnSystem, setCreatedOnSystem] = useState(false);

  const { customerIdentifier, rds } = props;

  async function fetchOnboardingDetail() {
    const response = await HttpService.getAxiosClient().get(
      rds
        ? `${window.API_URL}/onboarding?customerIdentifier=${customerIdentifier}&type=rds`
        : `${window.API_URL}/onboarding?customerIdentifier=${customerIdentifier}`
    );
    return response;
  }

  const {
    data: dataOnboardingDetail,
    isLoading: isLoadingOnboardingDetail,
    isError: isErrorOnboardingDetail,
    isFetching: isFetchingOnboardingDetail,
  } = useQuery({
    queryKey: ["onboardingDetail"],
    queryFn: fetchOnboardingDetail,
    refetchOnWindowFocus: false,
  });

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlaceDetail = useMemo(
    () => (request, callback) => {
      if (request && request.place_id) {
        const getInfo = (address_components, key) => {
          for (let i = 0; i < address_components.length; i++) {
            if (address_components[i].types.includes(key))
              return address_components[i].short_name
                ? address_components[i].short_name
                : "";
          }
          return "";
        };

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId: request.place_id }, syncWithForm);

        function syncWithForm(place, status) {
          setFormikSynced(
            "physicalAddressLine1",
            getInfo(place.address_components, "street_number") +
              " " +
              getInfo(place.address_components, "route"),
            setPhysicalAddressLine1
          );
          setFormikSynced(
            "physicalAddressLine2",
            getInfo(place.address_components, "subpremise"),
            setPhysicalAddressLine2
          );
          setFormikSynced(
            "physicalCity",
            getInfo(place.address_components, "locality"),
            setPhysicalCity
          );
          setFormikSynced(
            "physicalState",
            getInfo(place.address_components, "administrative_area_level_1"),
            setPhysicalState
          );
          setFormikSynced(
            "physicalZipCode",
            getInfo(place.address_components, "postal_code"),
            setPhysicalZipCode
          );
        }
      }
    },
    []
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    fetchPlaceDetail(value);
  }, [value, fetchPlaceDetail]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: ["us"],
        },
        fields: ["description", "place_id"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const loaded = useRef(false);

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
  }

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const validationSchema = yup.object({
    email: yup
      .string("Enter the member email")
      .email("Enter a valid email")
      .required("Email is required"),
    memberId: yup
      .string("Enter the member id")
      .required("Member id is required"),
    companyId: yup
      .string("Enter the company id")
      .required("Company id is required"),
    firstName: yup
      .string("Enter the member First name")
      .matches(/^\S*$/, "First name should not contain spaces")
      .required("First name is required"),
    middle: yup.string("Enter the member middle"),
    lastName: yup
      .string("Enter the member Last name")
      .matches(/^\S*$/, "Last name should not contain spaces")
      .required("Last name is required"),
    mobilePhone: yup
      .string("Enter the member mobile phone")
      .matches(
        /^^\(?[2-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
        "Enter a valid phone number"
      )
      .required("Mobile phone is required"),
    homePhone: yup.string("Enter the member home phone"),
    dob: yup.date().required("Birthday is required"),
    ssn: yup
      .string()
      .matches(
        /^((?!666|000)[0-8][0-9_]{2}(-)?(?!00)[0-9_]{2}(-)?(?!0000)[0-9_]{4})|(9\d{2})([ -]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ -]?)(\d{4})*$/,
        "Must be a valid SSN or ITIN"
      )
      .required("SSN is required"),
    physicalAddressLine1: yup
      .string("Enter the member Address Line 1")
      .required("Address line 1 is required"),
    physicalAddressLine2: yup.string("Enter the member Address Line 2"),
    physicalCity: yup
      .string("Enter the member city")
      .required("City is required"),
    physicalState: yup
      .string("Enter the member state")
      .required("State is required"),
    physicalZipCode: yup
      .string("Enter the member zip code")
      .required("zip is required"),
    mailingAddressLine1: yup
      .string("Enter the member Address Line 1")
      .required("Address line 1 is required"),
    mailingAddressLine2: yup.string("Enter the member Address Line 2"),
    mailingCity: yup
      .string("Enter the member city")
      .required("City is required"),
    mailingState: yup
      .string("Enter the member state")
      .required("State is required"),
    mailingZipCode: yup
      .string("Enter the member zip code")
      .required("zip is required"),
  });

  const updateOnboardingMutation = useMutation(
    (onboardingPayload) => {
      onboardingPayload.dob = new Date(onboardingPayload.dob)
        .toISOString()
        .split("T")[0];
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding`,
        onboardingPayload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["onboardings", "onboardings-started"]);
        setFeedback({
          open: true,
          type: "success",
          message: "onboarding updated succesfully",
          onClose: () => handleClose(),
        });
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      memberId: "",
      companyId: "",
      firstName: "",
      middle: "",
      lastName: "",
      mobilePhone: "",
      homePhone: "",
      dob: "",
      ssn: "",
      physicalAddressLine1: "",
      physicalAddressLine2: "",
      physicalCity: "",
      physicalState: "",
      physicalZipCode: "",
      mailingAddressLine1: "",
      mailingAddressLine2: "",
      mailingCity: "",
      mailingState: "",
      mailingZipCode: "",
      credential: "",
      idSeal: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateOnboardingMutation.mutate({
        ...values,
        previousEmail: customerIdentifier,
        type: rds ? "rds" : null,
      });
    },
  });

  const setFormikSynced = (key, value, callback) => {
    formik.setFieldValue(key, value, false);
    callback(value);
  };

  const wrapperFormikHandleChanged = (event) => {
    formik.handleChange(event);
    let callback = () => {};
    if (event.target.id === "physicalAddressLine1")
      callback = setPhysicalAddressLine1;
    if (event.target.id === "physicalAddressLine2")
      callback = setPhysicalAddressLine2;
    if (event.target.id === "physicalCity") callback = setPhysicalCity;
    if (event.target.id === "physicalState") callback = setPhysicalState;
    if (event.target.id === "physicalzipCode") callback = setPhysicalZipCode;
    if (event.target.id === "mailingAddressLine1")
      callback = setMailingAddressLine1;
    if (event.target.id === "mailingAddressLine2")
      callback = setMailingAddressLine2;
    if (event.target.id === "mailingCity") callback = setMailingCity;
    if (event.target.id === "mailingState") callback = setMailingState;
    if (event.target.id === "mailingzipCode") callback = setMailingZipCode;
    setFormikSynced(event.target.id, event.target.value, callback);
  };

  useEffect(() => {
    formik.resetForm();
    const { data } = dataOnboardingDetail || {};
    const {
      email,
      firstName,
      lastName,
      memberId: employeeNumber,
      ssn,
      dob,
      phone: mobilePhone,
      physicalAddressLine1: addressLine1FromServer,
      physicalAddressLine2: addressLine2FromServer,
      physicalCity: cityFromServer,
      physicalState: stateFromServer,
      physicalZipCode: zipCodeFromServer,
      mailingAddressLine1: mailingAddressLine1FromServer,
      mailingAddressLine2: mailingAddressLine2FromServer,
      mailingCity: mailingCityFromServer,
      mailingState: mailingStateFromServer,
      mailingZipCode: mailingZipCodeFromServer,
      createdOnSystem: createdOnSystemFromServer,
      companyId: employerNumber,
      _password: credential,
      idSeal,
    } = data || {};
    if (hasValue(email)) {
      formik.values.email = email;
    }

    if (hasValue(firstName)) {
      formik.values.firstName = firstName;
    }

    if (hasValue(lastName)) {
      formik.values.lastName = lastName;
    }

    if (employeeNumber !== undefined) {
      formik.values.memberId = employeeNumber;
    }

    if (employerNumber !== undefined) {
      formik.values.companyId = employerNumber;
    }

    if (ssn !== undefined) {
      formik.values.ssn = ssn;
    }

    if (mobilePhone !== undefined) {
      formik.values.mobilePhone = mobilePhone;
    }

    if (dob !== undefined) {
      formik.values.dob = dob;
    }

    if (credential !== undefined) {
      formik.values.credential = credential;
    }

    if (idSeal !== undefined) {
      formik.values.idSeal = idSeal;
    }

    if (hasValue(addressLine1FromServer)) {
      setPhysicalAddressLine1(addressLine1FromServer);
      setFormikSynced(
        "physicalAddressLine1",
        addressLine1FromServer,
        setPhysicalAddressLine1
      );
    }

    if (hasValue(addressLine2FromServer)) {
      setPhysicalAddressLine2(addressLine2FromServer);
      setFormikSynced(
        "physicalAddressLine2",
        addressLine2FromServer,
        setPhysicalAddressLine2
      );
    }

    if (hasValue(cityFromServer)) {
      setPhysicalCity(cityFromServer);
      setFormikSynced("physicalCity", cityFromServer, setPhysicalCity);
    }

    if (hasValue(stateFromServer)) {
      setPhysicalState(stateFromServer);
      setFormikSynced("physicalState", stateFromServer, setPhysicalState);
    }

    if (hasValue(zipCodeFromServer)) {
      setPhysicalZipCode(zipCodeFromServer);
      setFormikSynced("physicalZipCode", zipCodeFromServer, setPhysicalZipCode);
    }

    if (hasValue(mailingAddressLine1FromServer)) {
      setMailingAddressLine1(mailingAddressLine1FromServer);
      setFormikSynced(
        "mailingAddressLine1",
        mailingAddressLine1FromServer,
        setMailingAddressLine1
      );
    }

    if (hasValue(mailingAddressLine2FromServer)) {
      setMailingAddressLine2(mailingAddressLine2FromServer);
      setFormikSynced(
        "mailingAddressLine2",
        mailingAddressLine2FromServer,
        setMailingAddressLine2
      );
    }

    if (hasValue(mailingCityFromServer)) {
      setMailingCity(mailingCityFromServer);
      setFormikSynced("mailingCity", mailingCityFromServer, setMailingCity);
    }

    if (hasValue(mailingStateFromServer)) {
      setMailingState(mailingStateFromServer);
      setFormikSynced("mailingState", mailingStateFromServer, setMailingState);
    }

    if (hasValue(mailingZipCodeFromServer)) {
      setMailingZipCode(mailingZipCodeFromServer);
      setFormikSynced(
        "mailingZipCode",
        mailingZipCodeFromServer,
        setMailingZipCode
      );
    }

    if (hasValue(createdOnSystemFromServer)) {
      setCreatedOnSystem(createdOnSystemFromServer === "true");
    }
  }, [dataOnboardingDetail]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
    formik.resetForm();
    queryClient.invalidateQueries(["onboardingDetail"]);
  };

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
      setValue(null);
      setInputValue("");
      setPhysicalAddressLine1("");
      setPhysicalAddressLine2("");
      setPhysicalCity("");
      setPhysicalState("");
      setPhysicalZipCode("");
      setValue(null);
      setMailingAddressLine1("");
      setMailingAddressLine2("");
      setMailingCity("");
      setMailingState("");
      setMailingZipCode("");
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <DialogTitle>
          <ModeEditIcon variant="contained" />
          {props.dialogTitle}
          <AccountCircleIcon
            style={{ marginLeft: "20px", marginRight: "10px" }}
          />
          <span style={{ fontSize: "1rem" }}>{customerIdentifier}</span>{" "}
        </DialogTitle>
        <DialogContent>
          <br />
          {isFetchingOnboardingDetail || isLoadingOnboardingDetail ? (
            <CircularProgress color="inherit" />
          ) : isErrorOnboardingDetail ? (
            <h4>There was an error getting the info</h4>
          ) : (
            <Grid container spacing={1.5}>
              <Grid container spacing={1.5}>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="companyId"
                    type="text"
                    label="Company id"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.companyId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyId &&
                      Boolean(formik.errors.companyId)
                    }
                    helperText={
                      formik.touched.companyId && formik.errors.companyId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="memberId"
                    type="text"
                    label="Member id"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.memberId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.memberId && Boolean(formik.errors.memberId)
                    }
                    helperText={
                      formik.touched.memberId && formik.errors.memberId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    type="email"
                    label="Verified email"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <RenderOnRole roles={["onboarding-super-admin"]}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="credential"
                      type="text"
                      label="Credential"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.credential}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.credential &&
                        Boolean(formik.errors.credential)
                      }
                      helperText={
                        formik.touched.credential && formik.errors.credential
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                        display: "none",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="idSeal"
                      type="text"
                      label="Id Seal"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.idSeal}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.idSeal && Boolean(formik.errors.idSeal)
                      }
                      helperText={formik.touched.idSeal && formik.errors.idSeal}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                        display: "none",
                      }}
                    />
                  </Grid>
                </RenderOnRole>

                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="firstName"
                    type="text"
                    label="First Name"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    fullWidth
                    id="middle"
                    type="text"
                    disabled={!editMode}
                    label="Middle Initial"
                    variant="outlined"
                    value={formik.values.middle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.middle && Boolean(formik.errors.middle)
                    }
                    helperText={formik.touched.middle && formik.errors.middle}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="lastName"
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mobilePhone"
                    type="text"
                    label="Mobile Phone"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.mobilePhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mobilePhone &&
                      Boolean(formik.errors.mobilePhone)
                    }
                    helperText={
                      formik.touched.mobilePhone && formik.errors.mobilePhone
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="homePhone"
                    type="text"
                    label="Home Phone"
                    variant="outlined"
                    disabled={!editMode}
                    value={formik.values.homePhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.homePhone &&
                      Boolean(formik.errors.homePhone)
                    }
                    helperText={
                      formik.touched.homePhone && formik.errors.homePhone
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="dob"
                    type="date"
                    label="DOB"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    disabled={!editMode}
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="ssn"
                    type="text"
                    label="SSN or ITIN"
                    variant="outlined"
                    disabled={!editMode}
                    value={formik.values.ssn}
                    onChange={formik.handleChange}
                    error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                    helperText={formik.touched.ssn && formik.errors.ssn}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Divider style={{ margin: "10px" }} />

              <Grid container spacing={1.5}>
                <br />

                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    id="google-control"
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    hidden={!editMode} //works
                    disabled={!editMode}
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                      setValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter a location (physical address)"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting
                          .main_text_matched_substrings;
                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [
                          match.offset,
                          match.offset + match.length,
                        ])
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Box
                                component={LocationOnIcon}
                                sx={{ color: "text.secondary", mr: 2 }}
                              />
                            </Grid>
                            <Grid item xs>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}

                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div id="map2"></div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="physicalAddressLine1"
                    label="Address line 1"
                    value={physicalAddressLine1}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.physicalAddressLine1 &&
                      Boolean(formik.errors.physicalAddressLine1)
                    }
                    helperText={
                      formik.touched.physicalAddressLine1 &&
                      formik.errors.physicalAddressLine1
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="physicalAddressLine2"
                    label="Address line 2"
                    value={physicalAddressLine2}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.physicalAddressLine2 &&
                      Boolean(formik.errors.physicalAddressLine2)
                    }
                    helperText={
                      formik.touched.physicalAddressLine2 &&
                      formik.errors.physicalAddressLine2
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="physicalCity"
                    label="City"
                    value={physicalCity}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.physicalCity &&
                      Boolean(formik.errors.physicalCity)
                    }
                    helperText={
                      formik.touched.physicalCity && formik.errors.physicalCity
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="physicalState"
                    label="State"
                    value={physicalState}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.physicalState &&
                      Boolean(formik.errors.physicalState)
                    }
                    helperText={
                      formik.touched.physicalState &&
                      formik.errors.physicalState
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="physicalZipCode"
                    label="Zip Code"
                    value={physicalZipCode}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.physicalZipCode &&
                      Boolean(formik.errors.physicalZipCode)
                    }
                    helperText={
                      formik.touched.physicalZipCode &&
                      formik.errors.physicalZipCode
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: "10px" }} />

              <Grid container spacing={1.5}>
                <br />

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mailingAddressLine1"
                    label="Mailing Address line 1"
                    value={mailingAddressLine1}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.mailingAddressLine1 &&
                      Boolean(formik.errors.mailingAddressLine1)
                    }
                    helperText={
                      formik.touched.mailingAddressLine1 &&
                      formik.errors.mailingAddressLine1
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mailingAddressLine2"
                    label="Mailing Address line 2"
                    value={mailingAddressLine2}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.mailingAddressLine2 &&
                      Boolean(formik.errors.mailingAddressLine2)
                    }
                    helperText={
                      formik.touched.mailingAddressLine2 &&
                      formik.errors.mailingAddressLine2
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mailingCity"
                    label="Mailing City"
                    value={mailingCity}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.mailingCity &&
                      Boolean(formik.errors.mailingCity)
                    }
                    helperText={
                      formik.touched.mailingCity && formik.errors.mailingCity
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mailingState"
                    label="Mailing State"
                    value={mailingState}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.mailingState &&
                      Boolean(formik.errors.mailingState)
                    }
                    helperText={
                      formik.touched.mailingState && formik.errors.mailingState
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mailingZipCode"
                    label="Mailing Zip Code"
                    value={mailingZipCode}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.mailingZipCode &&
                      Boolean(formik.errors.mailingZipCode)
                    }
                    helperText={
                      formik.touched.mailingZipCode &&
                      formik.errors.mailingZipCode
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={"error"}>
            {updateOnboardingMutation.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              "Close"
            )}
          </Button>
          <Button
            disabled={!editMode || updateOnboardingMutation.isLoading}
            type="submit"
          >
            {props.action}
          </Button>
        </DialogActions>
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
