import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { findIndex, forEach } from "lodash";
import { useEffect, useState, useMemo } from "react";

//NOTE:
//In Code Sandbox these methods throw error while clearing the interval,
//but works fine in a actual project
// import { clearInterval, setInterval } from "worker-timers";

const useStylesCountDown = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "50px",
    height: "50px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    position: "relative",
  },
  bottom: {
    color: "#b2b2b2",
  },
  top: {
    animationDuration: "100ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  text: {
    fontWeight: "bold",
    fontSize: "0.9em",
    marginTop: "0.5em",
  },
}));

const CountDownTimer = (props) => {
  const classes = useStylesCountDown();
  const { duration, colors = [], colorValues = [], onComplete } = props;

  const [timeDuration, setTimeDuration] = useState(duration);
  const [countdownText, setCountdownText] = useState();
  const [countdownPercentage, setCountdownPercentage] = useState(100);
  const [countdownColor, setCountdownColor] = useState("#004082");

  useEffect(() => {
    let intervalId = setInterval(() => {
      setTimeDuration((prev) => {
        const newTimeDuration = prev - 1;
        const percentage = Math.ceil((newTimeDuration / timeDuration) * 100);
        setCountdownPercentage(percentage);

        if (newTimeDuration === 0) {
          clearInterval(intervalId);
          intervalId = null;
          onComplete();
        }

        return newTimeDuration;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, []);

  useEffect(() => {
    const minutes = Math.floor(timeDuration / 60);
    const seconds = timeDuration % 60;
    setCountdownText(`${minutes}:${seconds < 10 ? "0" + seconds : seconds}`);
  }, [timeDuration]);

  useEffect(() => {
    for (let i = 0; i < colorValues.length; i++) {
      const item = colorValues[i];
      if (timeDuration === item) {
        setCountdownColor(colors[i]);
        break;
      }
    }
  }, [timeDuration]);

  return (
    <>
      <Box
        className={classes.container}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",

          height: "100%", // Ensure the container takes the full height
        }}
      >
        <Box className={classes.top}>
          <Box className={classes.wrapper}>
            <CircularProgress
              className={classes.bottom}
              variant="determinate"
              size={30}
              thickness={4}
              value={100}
            />
            <CircularProgress
              className={classes.top}
              classes={{
                circle: classes.circle,
              }}
              variant="determinate"
              size={30}
              thickness={4}
              value={countdownPercentage}
              style={{
                transform: "scaleX(-1) rotate(-90deg)",
                color: countdownColor,
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{ width: "250px", marginTop: "30px" }}
          className={classes.root}
        >
          <Typography size="7" className={classes.text}>
            This window will close in {countdownText}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CountDownTimer;
