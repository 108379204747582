import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import { Fragment, useState } from "react";
import RefreshingIndicator from "../components/RefreshingIndicator";
import { DataGrid } from "@mui/x-data-grid";
import MoveBalance from "../components/MoveBalance";
import AddCompanyBalance from "../components/AddCompanyBalance";
import Movements from "../components/Movements";
import { vi } from "date-fns/locale";

export default function ManageBalance() {
  const [moveBalanceDlgData, moveAddBalanceDlgData] = useState(false);
  const [addCompanyDlg, setAddCompanyDlg] = useState(false);
  const [viewMovementsDlgData, setViewMovementsDlgData] = useState(false);
  const [viewAllMovementsDlgData, setViewAllMovementsDlgData] = useState(false);

  const viewMovementsDlgFunct = (event) => {
    return (
      <Dialog
        open={viewMovementsDlgData}
        onClose={() => {}}
        maxWidth="xl"
        fullWidth
      >
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>View Movements</DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setViewMovementsDlgData();
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Movements
            title={"Movements from " + viewMovementsDlgData.companyName}
            idBalance={viewMovementsDlgData.idBalance}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const viewAllMovementsDlgFunct = (event) => {
    return (
      <Dialog
        open={viewAllMovementsDlgData}
        onClose={() => {}}
        maxWidth="xl"
        fullWidth
      >
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>View All Movements</DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setViewAllMovementsDlgData();
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Movements title={"Movements from all balances "} idBalance={-1} />
        </DialogContent>
      </Dialog>
    );
  };

  const onAddBalanceClick = (e, params) => {
    params.idBalance = params.id;
    params.action = "CREDIT";
    moveAddBalanceDlgData(params);
  };

  const onDeductBalanceClick = (e, params) => {
    params.idBalance = params.id;
    params.action = "DEBIT";
    moveAddBalanceDlgData(params);
  };

  const onViewMovementsClick = (e, params) => {
    params.idBalance = params.id;
    setViewMovementsDlgData(params);
  };

  const onAddCompanyClick = () => setAddCompanyDlg(true);

  const queryConst = {
    main: {
      name: "balances",
      url: `/balance`,
    },
    count: {
      name: "balancesCount",
      url: "/balance/count",
    },
  };

  const dataColumns = [
    { field: "id", headerName: "ID", width: 90, hidden: true },
    {
      field: "companyName",
      headerName: "Company",
      width: 300,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.companyName}
          </Typography>
        );
      },
    },
    {
      field: "balance",
      headerAlign: "right",
      headerName: "Balance",
      width: 200,
      align: "right",
      renderCell: (params) => {
        if (params.value == null) return "";
        const valueFormatted =
          "$ " +
          Number(params.value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
        return <div style={{ fontSize: "13px" }}>{valueFormatted}</div>;
      },
    },
    {
      field: "detail",
      headerAlign: "right",
      width: 150,
      headerName: "Actions",
      align: "right",
      renderCell: (params) => (
        <div>
          <Tooltip title="Increase Balance">
            <Button style={{ minWidth: "10px", color: "green" }}>
              <AddIcon
                onClick={(e) => onAddBalanceClick(e, params.row)}
                variant="contained"
              />
            </Button>
          </Tooltip>
          <Tooltip title="Deduct Balance">
            <Button style={{ minWidth: "10px", color: "red" }}>
              <RemoveIcon
                onClick={(e) => onDeductBalanceClick(e, params.row)}
                variant="contained"
              />
            </Button>
          </Tooltip>
          <Tooltip title="View movements">
            <Button style={{ minWidth: "10px" }}>
              <MenuBookIcon
                onClick={(e) => onViewMovementsClick(e, params.row)}
                variant="contained"
              />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  async function fetchMainData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `${queryConst.main.url}`
    );
    return response;
  }

  const { data, isFetching, isLoading, isError } = useQuery(
    [queryConst.main.name],
    () => fetchMainData()
  );

  const moveBalanceDlg = (event) => {
    return (
      <Dialog
        open={moveBalanceDlgData}
        onClose={() => {}}
        PaperProps={{
          style: {
            width: "900px", // You can adjust the width as needed
            maxWidth: "none", // This prevents the maxWidth restriction
          },
        }}
      >
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography
                variant="h4"
                color={moveBalanceDlgData.action === "CREDIT" ? "green" : "red"}
              >
                {moveBalanceDlgData.action === "CREDIT"
                  ? "Increase Balance"
                  : "Deduct Balance"}
              </Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              moveAddBalanceDlgData();
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>{" "}
        </DialogActions>
        <DialogContent>
          <MoveBalance
            companyName={moveBalanceDlgData.companyName}
            open={moveBalanceDlgData ? true : false}
            action={moveBalanceDlgData.action}
            idBalance={moveBalanceDlgData.id}
            handleClose={() => {
              moveAddBalanceDlgData();
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const addCompanyDlgFunct = (event) => {
    return (
      <Dialog open={addCompanyDlg} onClose={() => {}}>
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography variant="h4">Add Company</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setAddCompanyDlg(false);
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <AddCompanyBalance handleClose={() => setAddCompanyDlg(false)} />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box>
      <Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "20px" }}>
            <Grid container alignItems="center">
              <Grid item pr={2}>
                <h1>Balances</h1>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onAddCompanyClick}>
                  Add Company
                </Button>
              </Grid>
              <Grid item sx={{ paddingLeft: "1rem" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setViewAllMovementsDlgData(!viewAllMovementsDlgData);
                  }}
                >
                  View All Movements
                </Button>
              </Grid>
            </Grid>
          </div>
          {isFetching ? <RefreshingIndicator /> : ""}
        </div>
        {isLoading ? (
          <CircularProgress color="inherit" />
        ) : !isError ? (
          <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
            <DataGrid
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              pageSize={10}
              rowsPerPageOptions={[10]}
              columnVisibilityModel={{ id: false }}
              rows={data?.data}
              columns={dataColumns}
            />
          </div>
        ) : (
          <h4>An error ocurred, please try again later...</h4>
        )}
        {moveBalanceDlgData ? moveBalanceDlg() : ""}
        {addCompanyDlg ? addCompanyDlgFunct() : ""}
        {viewMovementsDlgData ? viewMovementsDlgFunct() : ""}
        {viewAllMovementsDlgData ? viewAllMovementsDlgFunct() : ""}
      </Fragment>
    </Box>
  );
}
