import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState, forwardRef } from "react";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useFormik } from "formik";
import * as yup from "yup";
import throttle from "lodash/throttle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { hasValue } from "../util/string";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CircularProgress from "@mui/material/CircularProgress";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import RenderOnRole from "./RenderOnRole";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const autocompleteService = { current: null };

export default function AssignedCardDetail(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [createdOnSystem, setCreatedOnSystem] = useState(false);
  const [cardNumber, setCardNumber] = useState();

  // to setup visible fields
  const [ssn, setSsn] = useState("");
  const [hideMemberCode, setHideMemberCode] = useState(false);
  const [hideSsn, setHideSsn] = useState(false);
  const [hideAddress, setHideAddress] = useState(false);
  const [hidePhone, setHidePhone] = useState(false);
  const [hideDateOfBirth, setHideDateOfBirth] = useState(false);
  const [useLastFourOfSSN, setUseLastFourOfSSN] = useState(false);

  const initialValidationSchema = {
    email: yup
      .string("Enter the member email")
      .email("Enter a valid email")
      .required("Email is required"),
    firstName: yup
      .string("Enter the member First name")
      .matches(/^\S*$/, "First name should not contain spaces")
      .required("First name is required"),
    middle: yup.string("Enter the member middle"),
    lastName: yup
      .string("Enter the member Last name")
      .matches(/^\S*$/, "Last name should not contain spaces")
      .required("Last name is required"),
    homePhone: yup.string("Enter the member home phone"),
    addressLine1: yup
      .string("Enter the member Address Line 1")
      .required("Address line 1 is required"),
    addressLine2: yup.string("Enter the member Address Line 2"),
    city: yup.string("Enter the member city").required("City is required"),
    state: yup.string("Enter the member state").required("State is required"),
    zipCode: yup
      .string("Enter the member zip code")
      .required("zip is required"),
  };

  const [validationSchema, setValidationSchema] = useState(
    yup.object(initialValidationSchema)
  );

  const { idBundleDetail } = props;

  async function fetchPaycardDetail() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/` + idBundleDetail
    );
    setupFormWithServerData(response);
    return response;
  }

  const { isError: isErrorPaycardDetail, isFetching: isFetchingPayCardDetail } =
    useQuery({
      queryKey: ["paycardDetail"],
      queryFn: fetchPaycardDetail,
      refetchOnWindowFocus: false,
    });

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlaceDetail = useMemo(
    () => (request, callback) => {
      if (request && request.place_id) {
        const getInfo = (address_components, key) => {
          for (let i = 0; i < address_components.length; i++) {
            if (address_components[i].types.includes(key))
              return address_components[i].short_name
                ? address_components[i].short_name
                : "";
          }
          return "";
        };

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId: request.place_id }, syncWithForm);

        function syncWithForm(place, status) {
          setFormikSynced(
            "addressLine1",
            getInfo(place.address_components, "street_number") +
              " " +
              getInfo(place.address_components, "route"),
            setAddressLine1
          );
          setFormikSynced(
            "addressLine2",
            getInfo(place.address_components, "subpremise"),
            setAddressLine2
          );
          setFormikSynced(
            "city",
            getInfo(place.address_components, "locality"),
            setCity
          );
          setFormikSynced(
            "state",
            getInfo(place.address_components, "administrative_area_level_1"),
            setState
          );
          setFormikSynced(
            "zipCode",
            getInfo(place.address_components, "postal_code"),
            setZipCode
          );
        }
      }
    },
    []
  );

  const queryClient = useQueryClient();

  const onEditButtonClick = (e, params) => {
    setEditMode(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      employeeNo: "",
      firstName: "",
      middle: "",
      lastName: "",
      mobilePhone: "",
      homePhone: "",
      dob: "",
      ssn: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updatePaycardMutation.mutate({ ...values });
    },
  });

  const setupFormWithServerData = (dataPaycardDetail) => {
    const { data } = dataPaycardDetail;
    const {
      email,
      firstName,
      lastName,
      employeeNo: employeeNoFromServer,
      ssn: ssnFromServer,
      dob: dobFromServer,
      mobilePhone: mobilePhoneFromServer,
      addressLine1: addressLine1FromServer,
      addressLine2: addressLine2FromServer,
      city: cityFromServer,
      state: stateFromServer,
      zipCode: zipCodeFromServer,
      createdOnSystem: createdOnSystemFromServer,
      cardNumber: cardNumberFromServer,
    } = data?.payCardDetail;

    const {
      hideSSN,
      usePhoneAsMemberId,
      useProxyAsMemberId,
      hideAddress,
      useLastFourOfSSN,
      hidePhone,
      hideDOB,
    } = data?.payCardAssignProperties;

    if (hasValue(cardNumberFromServer)) {
      setCardNumber(cardNumberFromServer);
    }

    if (hasValue(email)) {
      formik.values.email = email;
    }

    if (hasValue(firstName)) {
      formik.values.firstName = firstName;
    }

    if (hasValue(lastName)) {
      formik.values.lastName = lastName;
    }

    if (hasValue(employeeNoFromServer)) {
      formik.values.employeeNo = employeeNoFromServer;
    }

    if (hasValue(ssnFromServer)) {
      if (hasValue(useLastFourOfSSN) && useLastFourOfSSN === "true") {
        formik.values.ssn = ssnFromServer.substr(ssnFromServer.length - 4);
      } else {
        formik.values.ssn = ssnFromServer;
      }
    }

    if (hasValue(mobilePhoneFromServer)) {
      formik.values.mobilePhone = mobilePhoneFromServer;
    }

    if (hasValue(dobFromServer)) {
      formik.values.dob = dobFromServer;
    }

    if (hasValue(addressLine1FromServer)) {
      setAddressLine1(addressLine1FromServer);
      setFormikSynced("addressLine1", addressLine1FromServer, setAddressLine1);
    }

    if (hasValue(addressLine2FromServer)) {
      setAddressLine2(addressLine2FromServer);
      setFormikSynced("addressLine2", addressLine2FromServer, setAddressLine2);
    }

    if (hasValue(cityFromServer)) {
      setCity(cityFromServer);
      setFormikSynced("city", cityFromServer, setCity);
    }

    if (hasValue(stateFromServer)) {
      setState(stateFromServer);
      setFormikSynced("state", stateFromServer, setState);
    }

    if (hasValue(zipCodeFromServer)) {
      setZipCode(zipCodeFromServer);
      setFormikSynced("zipCode", zipCodeFromServer, setZipCode);
    }

    if (hasValue(createdOnSystemFromServer)) {
      setCreatedOnSystem(createdOnSystemFromServer === "true");
    }

    if (hasValue(cardNumber)) {
      setCardNumber(cardNumber);
    }

    let finalValidationSchema = { ...initialValidationSchema };

    if (hasValue(hideSSN) && hideSSN === "true") {
      setHideSsn(true);
      setSsn(ssnFromServer);
    } else {
      if (hasValue(useLastFourOfSSN) && useLastFourOfSSN === "true") {
        setUseLastFourOfSSN(true);
        const ssnValidation = yup
          .string()
          .matches(/^(\d{4})*$/, "Must be the last four of the SSN")
          .required("The last four of SSN are required");
        finalValidationSchema = {
          ...finalValidationSchema,
          ssn: ssnValidation,
        };
      } else {
        const ssnValidation = yup
          .string()
          .matches(
            /^((?!666|000)[0-8][0-9\_]{2}(\-)?(?!00)[0-9\_]{2}(\-)?(?!0000)[0-9\_]{4})|(9\d{2})([ \-]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ \-]?)(\d{4})*$/,
            "Must be a valid SSN or ITIN"
          )
          .required("SSN is required");
        finalValidationSchema = {
          ...finalValidationSchema,
          ssn: ssnValidation,
        };
      }
    }

    if (hasValue(hidePhone) && hidePhone === "true") {
      setHidePhone(true);
    } else {
      const phoneValidation = yup
        .string("Enter the member mobile phone")
        .matches(
          /^^\(?[2-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
          "Enter a valid phone number"
        )
        .required("Mobile phone is required");
      finalValidationSchema = {
        ...finalValidationSchema,
        mobilePhone: phoneValidation,
      };
    }

    if (hasValue(hideDOB) && hideDOB === "true") {
      setHideDateOfBirth(true);
    } else {
      const dobValidation = yup.date().required("Birthday is required");
      finalValidationSchema = { ...finalValidationSchema, dob: dobValidation };
    }

    if (hasValue(hideAddress) && hideAddress === "true") {
      setHideAddress(true);
    }

    if (
      (hasValue(useProxyAsMemberId) && useProxyAsMemberId === "true") ||
      (hasValue(usePhoneAsMemberId) && usePhoneAsMemberId === "true")
    ) {
      setHideMemberCode(true);
    } else {
      const employeeValidation = yup
        .string("Enter the employee code")
        .required("Employee code is required");
      finalValidationSchema = {
        ...finalValidationSchema,
        employeeNo: employeeValidation,
      };
    }
    setValidationSchema(yup.object(finalValidationSchema));
  };

  useEffect(() => {
    fetchPlaceDetail(value);
  }, [value, fetchPlaceDetail]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: ["us"],
        },
        fields: ["description", "place_id"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const loaded = useRef(false);

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
  }

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const updatePaycardMutation = useMutation(
    (paycardPayload) => {
      if (useLastFourOfSSN) {
        paycardPayload.ssn = paycardPayload.ssn.padStart(9, "0");
      }
      return HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/` + idBundleDetail,
        paycardPayload
      );
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "paycard updated succesfully",
          onClose: () => handleClose(),
        });
        queryClient.invalidateQueries(["paycardsAssigned"]);
        queryClient.invalidateQueries(["paycardsStats"]);

        //queryClient.invalidateQueries({ queryKey: ['locations'] })
        //handleClose();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const setFormikSynced = (key, value, callback) => {
    formik.setFieldValue(key, value, false);
    callback(value);
  };

  const wrapperFormikHandleChanged = (event) => {
    formik.handleChange(event);
    let callback = () => {};
    if (event.target.id === "addressLine1") callback = setAddressLine1;
    if (event.target.id === "addressLine2") callback = setAddressLine2;
    if (event.target.id === "city") callback = setCity;
    if (event.target.id === "state") callback = setState;
    if (event.target.id === "zipCode") callback = setZipCode;
    setFormikSynced(event.target.id, event.target.value, callback);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };

  const handleSuccess = () => {
    //refetch();
    setFeedback({
      open: true,
      type: "success",
      message: "The request was sent",
      onClose: () => {
        //clearFunction();
        setFeedback({ open: false });
        handleClose();
      },
    });
  };

  const resendEmailOnboardingMutation = useMutation(
    (request) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/signup/communication/resend`,
        { email: request.email }
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onResendEmailButtonClick = (e, params) => {
    resendEmailOnboardingMutation.mutate({ email: params.email });
  };

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
      setValue(null);
      setInputValue("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setZipCode("");
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        {isFetchingPayCardDetail ? (
          <CircularProgress color="inherit" />
        ) : isErrorPaycardDetail ? (
          <h4>There was an error getting the info</h4>
        ) : (
          <>
            <DialogTitle>
              {props.dialogTitle}
              <CreditCardIcon
                style={{ marginLeft: "20px", marginRight: "10px" }}
              />
              <span style={{ fontSize: "1rem" }}>{cardNumber}</span>
            </DialogTitle>
            <DialogContent>
              <RenderOnRole roles={["pc-edit-detail"]}>
                <DialogContentText>
                  {!createdOnSystem ? (
                    props.dialogContentText
                  ) : (
                    <span>
                      The user is already created on the platform, the
                      onboarding process is already finalized, no changes can be
                      made from this tool
                    </span>
                  )}
                  {!createdOnSystem && (
                    <Button disabled={editMode} style={{ minWidth: "10px" }}>
                      <ModeEditIcon
                        onClick={(e) => onEditButtonClick(e)}
                        variant="contained"
                      />
                    </Button>
                  )}
                </DialogContentText>
              </RenderOnRole>

              {!createdOnSystem && (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    style={{ minWidth: "10px" }}
                    disabled={resendEmailOnboardingMutation.isLoading}
                  >
                    <Typography variant="caption">
                      Resend Sign up email
                    </Typography>
                    <ForwardToInboxIcon
                      sx={{ marginLeft: "1rem" }}
                      onClick={(e) =>
                        onResendEmailButtonClick(e, formik.values)
                      }
                      variant="contained"
                    />
                  </Button>
                </Box>
              )}
              <br />

              <Grid container spacing={1.5}>
                <Grid container spacing={1.5}>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: formik.values.email?.startsWith(
                        "paycard_noemail_"
                      )
                        ? "none"
                        : "block",
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      id="email"
                      type="email"
                      label="Verified email"
                      variant="outlined"
                      required
                      disabled={
                        !editMode ||
                        formik.values.email?.startsWith("paycard_noemail_")
                      }
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      style={{ display: hideMemberCode ? "none" : "block" }}
                      size="small"
                      fullWidth
                      id="employeeNo"
                      type="text"
                      label="Employee code"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.employeeNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.employeeNo &&
                        Boolean(formik.errors.employeeNo)
                      }
                      helperText={
                        formik.touched.employeeNo && formik.errors.employeeNo
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      size="small"
                      fullWidth
                      id="firstName"
                      type="text"
                      label="First Name"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      fullWidth
                      id="middle"
                      type="text"
                      disabled={!editMode}
                      label="Middle Initial"
                      variant="outlined"
                      value={formik.values.middle}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.middle && Boolean(formik.errors.middle)
                      }
                      helperText={formik.touched.middle && formik.errors.middle}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      size="small"
                      fullWidth
                      id="lastName"
                      type="text"
                      label="Last Name"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: hidePhone ? "none" : "block" }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      id="mobilePhone"
                      type="text"
                      label="Mobile Phone"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.mobilePhone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mobilePhone &&
                        Boolean(formik.errors.mobilePhone)
                      }
                      helperText={
                        formik.touched.mobilePhone && formik.errors.mobilePhone
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: hidePhone ? "none" : "block" }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      id="homePhone"
                      type="text"
                      label="Home Phone"
                      variant="outlined"
                      disabled={!editMode}
                      value={formik.values.homePhone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.homePhone &&
                        Boolean(formik.errors.homePhone)
                      }
                      helperText={
                        formik.touched.homePhone && formik.errors.homePhone
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: hideDateOfBirth ? "none" : "block" }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      id="dob"
                      type="date"
                      label="DOB"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      disabled={!editMode}
                      value={formik.values.dob}
                      onChange={formik.handleChange}
                      error={formik.touched.dob && Boolean(formik.errors.dob)}
                      helperText={formik.touched.dob && formik.errors.dob}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ display: hideSsn ? "none" : "block" }}
                      size="small"
                      fullWidth
                      id="ssn"
                      type="text"
                      label="SSN or ITIN"
                      variant="outlined"
                      disabled={!editMode}
                      value={formik.values.ssn}
                      onChange={formik.handleChange}
                      error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                      helperText={formik.touched.ssn && formik.errors.ssn}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Divider style={{ margin: "20px" }} />
                <div style={{ display: hideAddress ? "none" : "block" }}>
                  <Grid container spacing={1.5}>
                    <br />
                    <Grid item xs={12}>
                      <Autocomplete
                        size="small"
                        id="google-control"
                        getOptionLabel={(option) =>
                          typeof option === "string"
                            ? option
                            : option.description
                        }
                        filterOptions={(x) => x}
                        options={options}
                        autoComplete
                        hidden={!editMode} //works
                        disabled={!editMode}
                        includeInputInList
                        filterSelectedOptions
                        value={value}
                        onChange={(event, newValue) => {
                          setOptions(
                            newValue ? [newValue, ...options] : options
                          );
                          setValue(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Enter a location"
                            fullWidth
                          />
                        )}
                        renderOption={(props, option) => {
                          const matches =
                            option.structured_formatting
                              .main_text_matched_substrings;
                          const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [
                              match.offset,
                              match.offset + match.length,
                            ])
                          );

                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Box
                                    component={LocationOnIcon}
                                    sx={{ color: "text.secondary", mr: 2 }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}

                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {
                                      option.structured_formatting
                                        .secondary_text
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                              <div id="map"></div>
                            </li>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="addressLine1"
                        label="Address line 1"
                        value={addressLine1}
                        disabled={!editMode}
                        onChange={wrapperFormikHandleChanged}
                        error={
                          formik.touched.addressLine1 &&
                          Boolean(formik.errors.addressLine1)
                        }
                        helperText={
                          formik.touched.addressLine1 &&
                          formik.errors.addressLine1
                        }
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "slategray",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="addressLine2"
                        label="Address line 2"
                        value={addressLine2}
                        disabled={!editMode}
                        onChange={wrapperFormikHandleChanged}
                        error={
                          formik.touched.addressLine2 &&
                          Boolean(formik.errors.addressLine2)
                        }
                        helperText={
                          formik.touched.addressLine2 &&
                          formik.errors.addressLine2
                        }
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "slategray",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="city"
                        label="City"
                        value={city}
                        disabled={!editMode}
                        onChange={wrapperFormikHandleChanged}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "slategray",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="state"
                        label="State"
                        value={state}
                        disabled={!editMode}
                        onChange={wrapperFormikHandleChanged}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "slategray",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="zipCode"
                        label="Zip Code"
                        value={zipCode}
                        disabled={!editMode}
                        onChange={wrapperFormikHandleChanged}
                        error={
                          formik.touched.zipCode &&
                          Boolean(formik.errors.zipCode)
                        }
                        helperText={
                          formik.touched.zipCode && formik.errors.zipCode
                        }
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "slategray",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color={"error"}>
                {updatePaycardMutation.isLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Close"
                )}
              </Button>
              <Button
                disabled={!editMode || updatePaycardMutation.isLoading}
                type="submit"
              >
                {props.action}
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
