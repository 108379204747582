import React, { useEffect } from "react";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import CardImage from "./CardImage";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import CountDownTimer from "./CountDownTimer";

const PanView = ({ onTimeout, cardService, memberId, companyId }) => {
  const [btnDisplay, setBtnDisplay] = React.useState("block");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerWidth);
  const [showTimer, setShowTimer] = React.useState(false);

  async function fetchProcesorData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL +
        `/member/card-token?memberId=${memberId}&companyId=${companyId}`
    );
    return response;
  }

  const { data, isLoading, isFetching } = useQuery(
    ["member-card-token-info", memberId, companyId],
    () => fetchProcesorData()
  );

  const theme = useTheme();

  useEffect(() => {
    if (isMobile) {
      setWidth(320);
      setHeight(340);
    } else {
      setWidth(380);
      setHeight(390);
    }
  }, [isMobile]);

  const cardImage = CardImage();

  const callWidget = (cardToken) => {
    const urlScript = window.ism_widget_url;

    const whenLoad = () => {
      const widget = {
        onLoadIframe: (iframe_loaded) => {},
        onSuccess: (details) => {
          setShowTimer(true);
        },
        onFailure: (details) => {},
        frame: {
          container_id: "widget_container",
          iframe_class: "widget-iframe",
          filter:
            "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%)",
        },
        target_origin: window.ism_target_origin,
        config: {
          token: cardToken,
          main: "show_pan",
          css: {
            container: {
              border: 0,
              margin: 0,
              overflowX: "hidden",
              padding: 0,
            },
            vc: {
              color: "black",
            },
            vc_bg: {
              "background-image": `url(${origin + cardImage})`,
            },
            vc_number: {
              top: "69%",
              left: "-10%",
              color: "white",
              "font-size": "16px",
            },
            vc_logo: {
              display: "none",
            },
            vc_network: {
              display: "none",
            },
            "btn-sm": {
              display: btnDisplay,
              color: "white",
              backgroundColor: "teal",
              borderRadius: "15px", // Add this line to round the border
              height: "40px",
              border: "none",
            },
            "mt-2": {
              display: "none",
            },
            vc_exp_date: {
              color: "white",
              left: "-5%",
              "font-size": "16px",
            },
            body: {},
            "col-12": {
              display: "none",
            },
          },
          content: {
            submit_text: "SHOW",
          },
        },
      };

      const iframe = document.getElementById("widget_iframe");
      const pciWidget = iframe.contentWindow.pciWidget;
      pciWidget(widget);

      const widget_container =
        iframe.contentDocument.getElementById("widget_container");
      if (widget_container) {
        widget_container.style.width = `${width}px`;
        widget_container.style.height = `${height}px`;
        widget_container.style.overflow = "hidden";
      }

      const widget_frame =
        iframe.contentDocument.getElementById("widget_frame");
      if (widget_frame) {
        widget_frame.style.width = widget_container.style.width;
        widget_frame.style.height = widget_container.style.height;
        widget_frame.style.overflow = "hidden";
      }
    };

    const iframe = document.getElementById("widget_iframe");

    const script = document.createElement("script");
    script.src = urlScript;
    script.addEventListener("load", () => whenLoad());

    iframe.contentDocument.body.appendChild(script);
    const div = iframe.contentDocument.createElement("div");
    div.id = "widget_container";
    div.name = "widget_container";
    iframe.contentDocument.body.appendChild(div);

    return () => {};
  };

  React.useEffect(() => {
    if (data?.data?.cardToken) callWidget(data?.data?.cardToken);
  }, [data?.data?.cardToken]);

  return (
    <>
      <iframe
        title="QoloViewPanWidget"
        id="widget_iframe"
        width={width + 30}
        height={height + 20}
        style={{
          border: "none",
          overflow: "hidden",
          margin: "auto",
          display: "block",
        }}
      ></iframe>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80vh",
          height: "5vh", // Adjust as needed
        }}
      >
        {showTimer && (
          <CountDownTimer
            duration={20}
            colors={["#ff9248", "#a20000"]}
            colorValues={[20, 10]}
            onComplete={() => onTimeout()}
          />
        )}
      </div>
    </>
  );
};

export default PanView;
