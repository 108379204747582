import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Container, Grid, Button, Typography } from "@mui/material";
import locationImg from "./../images/locations.svg";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
// import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
// import DeleteIcon from "@mui/icons-material/Delete";
import SetLocation from "../components/SetLocation";
import RenderOnRole from "../components/RenderOnRole";

export default function Locations() {
  const columns = [
    { field: "id", headerName: "ID", width: 1, hide: true },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              minWidth: "4rem",
              display: "flex",
              flexDirection: "column",
              alignContent: "left",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <Typography fontSize={12} sx={{ width: "33%", flexShrink: 0 }}>
              {params.row.description}
            </Typography>
            <Typography
              fontSize={12}
              sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }}
            >
              {params.row.external_id}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                minWidth: "4rem",
                display: "flex",
                flexDirection: "column",
                alignContent: "left",
                alignItems: "flex-start",
                textAlign: "left",
              }}
            >
              <Typography
                fontSize="0.9rem"
                sx={{ width: "45%", flexShrink: 0 }}
              >
                {params.row.address_line_1}{" "}
                {params.row.address_line_2 !== null &&
                params.row.address_line_2 !== undefined &&
                params.row.address_line_2 !== ""
                  ? "( " + params.row.address_line_2 + " )"
                  : ""}
              </Typography>
              <Typography
                fontSize={12}
                sx={{
                  fontSize: "0.7rem",
                  mb: 0,
                  fontWeight: "bold",
                  color: "gray",
                }}
                gutterBottom
              >
                {params.row.city}, {params.row.state} {params.row.zip}
              </Typography>
            </div>
          </>
        );
      },
    },
    {
      field: "inventoryManager",
      headerName: "Inventory Manager",
      width: 200,
      renderCell: (params) => {
        if (params.row.first_name) {
          return (
            <div
              style={{
                minWidth: "4rem",
                display: "flex",
                flexDirection: "column",
                alignContent: "left",
                alignItems: "flex-start",
                textAlign: "left",
              }}
            >
              <Typography fontSize={12} sx={{ width: "33%", flexShrink: 0 }}>
                {params.row.first_name + " " + params.row.last_name}
              </Typography>
              <Typography fontSize={12} sx={{ width: "33%", flexShrink: 0 }}>
                {params.row.email}
              </Typography>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography fontSize={12} sx={{ width: "33%", flexShrink: 0 }}>
            {params.row.phone}
          </Typography>
        );
      },
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   renderCell: (params) => {
    //     return (
    //       // you will find row info in params
    //       <div>
    //         <Button style={{ minWidth: "10px" }}>
    //           <EditLocationAltIcon
    //             onClick={(e) => onEditButtonClick(e, params.row)}
    //             variant="contained"
    //           />
    //         </Button>
    //         <Button style={{ minWidth: "10px" }}>
    //           <DeleteIcon
    //             onClick={(e) => onDeleteButtonClick(e, params.row)}
    //             variant="contained"
    //           />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onAddButtonClick = (e, params) => {
    setOpenNewDlg(true);
  };

  async function fetchLocations() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/locations`
    );
    return response;
  }

  const { data, isLoading, isError } = useQuery(["locations"], fetchLocations);

  const [openNewDlg, setOpenNewDlg] = useState(false);

  return (
    <Container maxWidth="xlg">
      <br />
      <br />
      <Grid container spacing={1.5}>
        <Grid item xs={2} md={2}>
          <br />
          <br />
          <img src={locationImg} alt="locations" width="80%" loading="lazy" />
        </Grid>
        <Grid item xs={12} md={8}>
          <h1>Locations</h1>

          {isLoading ? (
            <h4>Loading...</h4>
          ) : !isError ? (
            <div style={{ height: "70vh", width: "100%" }}>
              <RenderOnRole roles={["pc-create-locations"]}>
                <Button
                  style={{ margin: "5px" }}
                  onClick={(e) => onAddButtonClick(e)}
                  variant="contained"
                >
                  <AddLocationAltIcon></AddLocationAltIcon>
                  New Location
                </Button>
              </RenderOnRole>
              <DataGrid
                rows={data?.data}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
              />
            </div>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </Grid>
      </Grid>

      <SetLocation
        action="Create"
        open={openNewDlg}
        setOpen={setOpenNewDlg}
        dialogTitle="Create a Location"
        dialogContentText="To create a location, input the address information and click create button."
      />
    </Container>
  );
}
