import React, { Fragment, useEffect, useState, forwardRef } from "react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshingIndicator from "./RefreshingIndicator";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaymentsDetail from "./PaymentsDetail";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import DownloadIcon from "@mui/icons-material/Download";
import RenderOnRole from "./RenderOnRole";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { lte } from "lodash";

export default function Movements(props) {
  const [page, setPage] = useState(0);
  const [filterString, setFilterString] = useState("ALL");
  const [openNewDlg, setOpenNewDlg] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [select, setSelection] = useState([]);

  const [dateFromValue, setDateFromValue] = React.useState(null);
  const [dateToValue, setDateToValue] = React.useState(null);

  const [cleared, setCleared] = React.useState(false);

  async function fetchMovements(page, idBalance, filterString) {
    const from = dateFromValue
      ? dayjs(dateFromValue).format("YYYY-MM-DDTHH:mm:ss")
      : "2022-01-01T00:00:00";
    const to = dateToValue
      ? dayjs(dateToValue).format("YYYY-MM-DDTHH:mm:ss")
      : "2034-01-31T23:59:59";
    const response = await HttpService.getAxiosClient().get(
      window.API_URL +
        `/balance/movements?pageSize=100&page=${page}&from=${from}&to=${to}&idBalance=${idBalance}&filterString=${filterString}&idEmployer=${props.idEmployer}`
    );
    return response;
  }

  async function fetchMovementsCount(idBalance, filterString) {
    const from = dateFromValue
      ? dayjs(dateFromValue).format("YYYY-MM-DDTHH:mm:ss")
      : "2022-01-01T00:00:00";
    const to = dateToValue
      ? dayjs(dateToValue).format("YYYY-MM-DDTHH:mm:ss")
      : "2034-01-31T23:59:59";
    const response = await HttpService.getAxiosClient().get(
      window.API_URL +
        `/balance/movements/count?from=${from}&to=${to}&idBalance=${idBalance}&filterString=${filterString}&idEmployer=${props.idEmployer}`
    );
    return response;
  }

  const { data, isFetching, isLoading, isError } = useQuery(
    [
      "movements",
      page,
      filterString,
      props.idBalance,
      dateFromValue,
      dateToValue,
    ],
    () => fetchMovements(page, props.idBalance, filterString),
    {
      keepPreviousData: true,
    }
  );

  const { data: dataCount, isLoading: isLoadingDataCount } = useQuery(
    [
      "movementsCount",
      filterString,
      props.idBalance,
      dateFromValue,
      dateToValue,
    ],
    () => fetchMovementsCount(props.idBalance, filterString),
    {
      keepPreviousData: true,
    }
  );

  const downloaMovementsMutation = useMutation(
    (params) => {
      const from = dateFromValue
        ? dayjs(dateFromValue).format("YYYY-MM-DDTHH:mm:ss")
        : "2022-01-01T00:00:00";
      const to = dateToValue
        ? dayjs(dateToValue).format("YYYY-MM-DDTHH:mm:ss")
        : "2034-01-31T23:59:59";
      return HttpService.getAxiosClient().get(
        window.API_URL +
          `/balance/movements/export?idBalance=${props.idBalance}&from=` +
          from +
          `&to=` +
          to +
          `&filterString=${filterString}&idEmployer=${props.idEmployer}`,
        { responseType: "blob" }
      );
    },
    {
      onSuccess: (response) => {
        const { headers, data } = response;
        const disposition = headers["content-disposition"];
        let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace("utf-8''", ""));
        else filename = filename.replace(/['"]/g, "");
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element
        setFeedback({
          open: true,
          type: "success",
          message: "The file was download succesfully",
          onClose: () => {
            setFeedback({ open: false });
          },
        });
      },
      onError: (err) => {
        if (err?.response?.status === 500) {
          setFeedback({
            open: true,
            type: "error",
            message: "There was an error trying to download the file",
          });
        } else {
          const msg = err?.response?.data ? err.response.data : err.message;
          setFeedback({
            open: true,
            type: "error",
            message: msg,
          });
        }
      },
    }
  );

  const onDownloadDetailsButtonClick = (e, params) => {
    downloaMovementsMutation.mutate(null);
  };

  const onViewButtonClick = (e, params) => {
    const paymentInfo = {
      description: params.description,
      idEmployer: params.reference2,
      idPayFile: params.reference3,
      numberOfRecords: "N/A",
      paymentDate: "N/A",
      processStatus: "N/A",
      totalAmount: "N/A",
      type: "N/A",
      payFileDetail: {
        lineNumber: params.reference4,
      },
    };
    setPaymentInfo(paymentInfo);
    setOpenNewDlg(true);
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let dataColumns = [
    { field: "id", headerName: "ID", width: 90, hidden: true },
    {
      field: "movementDate",
      headerName: "Date",
      width: 160,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.movementDate.replace("T", " ")}
          </Typography>
        );
      },
    },
    {
      field: "movementType",
      headerName: "Type",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.movementType}
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 10, mb: 0 }} gutterBottom>
            {params.row.description}
          </Typography>
        );
      },
    },
    {
      field: "reference2",
      headerName: "Reference",
      flex: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 10, mb: 0 }} gutterBottom>
            {params.row.reference2}
          </Typography>
        );
      },
    },
    {
      field: "amount",
      headerAlign: "center",
      headerName: "Amount",
      width: 120,
      align: "right",
      renderCell: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted =
          "$ " +
          Number(params.value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
        return (
          <div
            style={{
              fontSize: "13px",
              color: params.value < 0 ? "red" : "green",
            }}
          >
            {valueFormatted}
          </div>
        );
      },
    },
    {
      field: "detail",
      flex: 1,
      headerAlign: "right",
      headerName: "Details",
      align: "right",
      renderCell: (params) => {
        if (params.row.movementType === "PAYFILE")
          return (
            <RenderOnRole roles={["pf-viewer"]}>
              <Button style={{ minWidth: "10px" }}>
                <VisibilityIcon
                  onClick={(e) => onViewButtonClick(e, params.row)}
                  variant="contained"
                />
              </Button>
            </RenderOnRole>
          );
        else return "";
      },
    },
  ];

  const filteredColumns =
    props.idBalance !== -1
      ? dataColumns.filter((column) => column.field !== "reference2")
      : dataColumns;

  const columns = React.useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: "Change Location",
        width: 100,
      },
      ...dataColumns,
    ],
    [dataColumns]
  );

  const handleRowSelection = (rows) => {
    setSelection(rows);
  };

  useEffect(() => {}, [select]);

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  return (
    <Fragment>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "20px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <h1>{props.title}</h1>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                style={{ margin: "10px", height: "40px", width: "350px" }}
                onClick={onDownloadDetailsButtonClick}
                variant="contained"
                disabled={downloaMovementsMutation.isLoading}
                color={
                  downloaMovementsMutation.isLoading ? "secondary" : "primary"
                }
              >
                <DownloadIcon style={{ paddingRight: "5px" }} />
                {downloaMovementsMutation.isLoading
                  ? "Downloading..."
                  : "Export Balance Movements"}
              </Button>
            </div>
          </div>
          <div style={{ minWidth: "15rem" }}>
            {isFetching ? <RefreshingIndicator /> : ""}
          </div>
          <FormControl>
            <RadioGroup
              row
              style={{ justifyContent: "flex-end" }}
              value={filterString}
              onChange={(event) => {
                setFilterString(event.target.value);
              }}
            >
              <FormControlLabel
                value="CREDIT"
                control={<Radio />}
                label="Credits"
              />
              <FormControlLabel
                value="DEBIT"
                control={<Radio />}
                label="Debits"
              />
              <FormControlLabel value="ALL" control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: "0.5rem",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date from:"
              defaultValue={null}
              value={dateFromValue}
              onChange={(newValue) => {
                setDateFromValue(
                  newValue
                ); /*setDateFromFunction (newValue); refetchFunction();*/
              }}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => {
                    setCleared(true);
                    setDateToValue(null);
                  },
                },
              }}
            />
            <DatePicker
              sx={{ marginLeft: "0.5rem" }}
              label="Date to:"
              value={dateToValue}
              onChange={(newValue) => {
                setDateToValue(
                  newValue
                ); /*setDateToFunction (newValue); refetchFunction();*/
              }}
              slotProps={{
                field: { clearable: true, onClear: () => setCleared(true) },
              }}
              minDate={dayjs(dateFromValue).add(1, "day")}
              disabled={dateFromValue === null}
            />
          </LocalizationProvider>
        </div>
      </div>

      {isLoading || isLoadingDataCount ? (
        <CircularProgress color="inherit" />
      ) : !isError ? (
        <div style={{ height: 450, width: "100%" }}>
          <DataGrid
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            pageSize={100}
            rowsPerPageOptions={[100]}
            columnVisibilityModel={{ id: false }}
            onSelectionModelChange={handleRowSelection}
            selectionModel={select}
            getRowsToExport={() => data.rows}
            rows={data?.data}
            columns={filteredColumns}
            rowCount={dataCount?.data}
            onPageChange={(newPage) => {
              setPage((prevPage) =>
                prevPage !== newPage ? newPage : prevPage
              );
            }}
            paginationMode="server"
            filterMode="server"
            keepNonExistentRowsSelected
          />
          <Snackbar
            open={feedback.open}
            autoHideDuration={6000}
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={
                feedback.onClose ? feedback.onClose : handleFeedbackClose
              }
              severity={feedback.type}
              sx={{ width: "100%" }}
            >
              {feedback.message}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <h4>An error ocurred, please try again later...</h4>
      )}
      {openNewDlg ? (
        <PaymentsDetail
          open={openNewDlg}
          setOpen={setOpenNewDlg}
          dialogTitle="Payment Batch Details"
          paymentInfo={paymentInfo}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}
