import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState } from "react";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useFormik } from "formik";
import * as yup from "yup";
import throttle from "lodash/throttle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { Label } from "@mui/icons-material";

const autocompleteService = { current: null };

export default function SetLocation(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [address_line_1, setaddress_line_1] = useState("");
  const [address_line_2, setaddress_line_2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setzip] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [external_id, setexternal_id] = useState("");

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlaceDetail = useMemo(
    () => (request, callback) => {
      if (request && request.place_id) {
        const getInfo = (address_components, key) => {
          for (let i = 0; i < address_components.length; i++) {
            if (address_components[i].types.includes(key))
              return address_components[i].short_name
                ? address_components[i].short_name
                : "";
          }
          return "";
        };

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId: request.place_id }, syncWithForm);

        function syncWithForm(place, status) {
          setFormikSynced(
            "address_line_1",
            getInfo(place.address_components, "street_number") +
              " " +
              getInfo(place.address_components, "route"),
            setaddress_line_1
          );
          setFormikSynced(
            "address_line_2",
            getInfo(place.address_components, "subpremise"),
            setaddress_line_2
          );
          setFormikSynced(
            "city",
            getInfo(place.address_components, "locality"),
            setCity
          );
          setFormikSynced(
            "state",
            getInfo(place.address_components, "administrative_area_level_1"),
            setState
          );
          setFormikSynced(
            "zip",
            getInfo(place.address_components, "postal_code"),
            setzip
          );
        }
      }
    },
    []
  );

  useEffect(() => {
    fetchPlaceDetail(value);
  }, [value, fetchPlaceDetail]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: ["us"],
        },
        fields: ["description", "place_id"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const loaded = useRef(false);

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
  }

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const validationSchema = yup.object({
    description: yup
      .string("Enter the description for this location")
      .required("A description is required"),
    address_line_1: yup
      .string("Enter the Address Line 1")
      .required("Address line 1 is required"),
    address_line_2: yup.string("Enter the Address Line 2"),
    city: yup.string("Enter the city").required("City is required"),
    state: yup.string("Enter the state").required("State is required"),
    zip: yup.string("Enter the employee zip code").required("zip is required"),
    first_name: yup
      .string("Enter the first name of the inventory manager")
      .required("First name is required"),
    last_name: yup
      .string("Enter the last name of the inventory manager")
      .required("Last name is required"),
    email: yup
      .string("Enter the email of the inventory manager")
      .required("Email is required"),
    phone: yup
      .string("Enter the phone of the inventory manager")
      .required("Phone is required"),
  });

  const queryClient = useQueryClient();

  const createLocationMutation = useMutation(
    (newLocation) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/locations`,
        newLocation
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["locations"] });
        handleClose();
      },
      onError: (err) => {
        const msg = err?.response?.data ? err.response.data : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      description: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createLocationMutation.mutate({ ...values });
    },
  });

  const setFormikSynced = (key, value, callback) => {
    formik.setFieldValue(key, value, false);
    callback(value);
  };

  const wrapperFormikHandleChanged = (event) => {
    formik.handleChange(event);
    let callback = () => {};
    if (event.target.id === "address_line_1") callback = setaddress_line_1;
    if (event.target.id === "address_line_2") callback = setaddress_line_2;
    if (event.target.id === "city") callback = setCity;
    if (event.target.id === "state") callback = setState;
    if (event.target.id === "zip") callback = setzip;

    if (event.target.id === "first_name") callback = setfirst_name;
    if (event.target.id === "last_name") callback = setlast_name;
    if (event.target.id === "email") callback = setemail;
    if (event.target.id === "phone") callback = setphone;
    if (event.target.id === "external_id") callback = setexternal_id;

    setFormikSynced(event.target.id, event.target.value, callback);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
      setValue(null);
      setInputValue("");
      setaddress_line_1("");
      setaddress_line_2("");
      setCity("");
      setState("");
      setzip("");
      setfirst_name("");
      setlast_name("");
      setemail("");
      setphone("");
      setexternal_id("");
    }
  }, [props.open]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        disableEscapeKeyDown={true}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle>{props.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.dialogContentText}</DialogContentText>
            <br />
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="description"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  id="google-control"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={value}
                  onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Enter a location" fullWidth />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match) => [
                        match.offset,
                        match.offset + match.length,
                      ])
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={LocationOnIcon}
                              sx={{ color: "text.secondary", mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                        <div id="map"></div>
                      </li>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="address_line_1"
                  label="Address line 1"
                  value={address_line_1}
                  onChange={wrapperFormikHandleChanged}
                  error={
                    formik.touched.address_line_1 &&
                    Boolean(formik.errors.address_line_1)
                  }
                  helperText={
                    formik.touched.address_line_1 &&
                    formik.errors.address_line_1
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="address_line_2"
                  label="Address line 2"
                  value={address_line_2}
                  onChange={wrapperFormikHandleChanged}
                  error={
                    formik.touched.address_line_2 &&
                    Boolean(formik.errors.address_line_2)
                  }
                  helperText={
                    formik.touched.address_line_2 &&
                    formik.errors.address_line_2
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="city"
                  label="City"
                  value={city}
                  onChange={wrapperFormikHandleChanged}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="state"
                  label="State"
                  value={state}
                  onChange={wrapperFormikHandleChanged}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="zip"
                  label="Zip Code"
                  value={zip}
                  onChange={wrapperFormikHandleChanged}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Inventory Manager Information</Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="first_name"
                  label="First Name"
                  value={first_name}
                  onChange={wrapperFormikHandleChanged}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  value={last_name}
                  onChange={wrapperFormikHandleChanged}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="email"
                  label="Email"
                  value={email}
                  onChange={wrapperFormikHandleChanged}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="phone"
                  label="Phone"
                  value={phone}
                  onChange={wrapperFormikHandleChanged}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>External Identifier (Optional)</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="external_id"
                  label="External ID"
                  value={external_id}
                  onChange={wrapperFormikHandleChanged}
                  error={
                    formik.touched.external_id &&
                    Boolean(formik.errors.external_id)
                  }
                  helperText={
                    formik.touched.external_id && formik.errors.external_id
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color={"error"}>
              Cancel
            </Button>
            <div style={{ minWidth: "3em" }}>
              {createLocationMutation.isLoading ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                <Button type="submit">{props.action}</Button>
              )}
            </div>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
}
