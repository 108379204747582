import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";
import Movements from "../components/Movements";
import CardStatsElement from "../components/CardStatsElement";
import RenderIfNotOnRole from "../components/RenderIfNotOnRole";

export default function QueryBalance() {
  async function fetchBalanceStats() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/balance/stats`
    );
    return response;
  }

  const {
    data: dataStats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
  } = useQuery(["balanceStats"], fetchBalanceStats);

  return (
    <RenderIfNotOnRole roles={["pf-multibalance"]}>
      <Box sx={{ margin: "0", padding: "0 150px" }}>
        <br />

        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <CardStatsElement
                headerTypograhy="h3"
                minHeight={185}
                fontSize={50}
                md={12}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Current Balance"
                data={dataStats && dataStats.data.currentBalance}
                type="amount"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Grid container spacing={1}>
              <CardStatsElement
                headerTypograhy="h6"
                minWidth={10}
                fontSize={20}
                md={4}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Credits this month"
                data={dataStats && dataStats.data.creditsThisMonth}
                type="amount"
              />
              <CardStatsElement
                headerTypograhy="h6"
                minWidth={10}
                fontSize={20}
                md={4}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Credits this week"
                data={dataStats && dataStats.data.creditsThisWeek}
                type="amount"
              />
              <CardStatsElement
                headerTypograhy="h6"
                minWidth={10}
                fontSize={20}
                md={4}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Credits today"
                data={dataStats && dataStats.data.creditsToday}
                type="amount"
              />
            </Grid>
            <Grid container spacing={1}>
              <CardStatsElement
                headerTypograhy="h6"
                minWidth={10}
                fontSize={20}
                md={4}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Debits this month"
                data={dataStats && dataStats.data.debitsThisMonth}
                type="amount"
              />
              <CardStatsElement
                headerTypograhy="h6"
                minWidth={10}
                fontSize={20}
                md={4}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Debits this week"
                data={dataStats && dataStats.data.debitsThisWeek}
                type="amount"
              />
              <CardStatsElement
                headerTypograhy="h6"
                minWidth={10}
                fontSize={20}
                md={4}
                isErrorStats={isErrorStats}
                isLoadingStats={isLoadingStats}
                text="Debits today"
                data={dataStats && dataStats.data.debitsToday}
                type="amount"
              />
            </Grid>
          </Grid>
        </Grid>

        <br />
        <br />
        <Movements title="Movements" />
      </Box>
    </RenderIfNotOnRole>
  );
}
