import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import {
  Container,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import statsImg from "./../images/presentation.svg";
import DownloadIcon from "@mui/icons-material/Download";
import { useMutation } from "@tanstack/react-query";

export default function FlashReports() {
  const columns = [
    { field: "id_execution", headerName: "ID", width: 140, hide: false },
    {
      field: "description",
      headerName: "Report Name",
      width: 500,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              minWidth: "4rem",
              display: "flex",
              flexDirection: "column",
              alignContent: "left",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <Typography fontSize={14} sx={{ width: "33%", flexShrink: 0 }}>
              {params.row.aux_val_01}
            </Typography>
            <Typography
              fontSize={12}
              sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }}
            >
              {params.row.last_date_id}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        var buttonStyle = {
          minWidth: "40px",
        };
        if (
          itemDownloading !== null &&
          itemDownloading !== params.row.id_execution
        ) {
          buttonStyle = {
            minWidth: "40px",
            pointerEvents: "none",
            opacity: "0.4",
          };
        }
        return (
          // you will find row info in params
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {itemDownloading === params.row.id_execution ? (
              <CircularProgress size={20} />
            ) : (
              <Button style={buttonStyle}>
                <DownloadIcon
                  onClick={(e) => onDownloadButtonClick(e, params.row)}
                  variant="contained"
                />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const [itemDownloading, setItemDownloading] = useState(null);

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const downloadReportMutation = useMutation(
    (params) => {
      setItemDownloading(params.id_execution);
      return HttpService.getAxiosClient().get(
        window.API_URL + `/report/flash/` + params.id_execution,
        { responseType: "blob" }
      );
    },
    {
      onSuccess: (response) => {
        const { headers, data } = response;
        const disposition = headers["content-disposition"];
        let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace("utf-8''", ""));
        else filename = filename.replace(/['"]/g, "");
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element
        setItemDownloading(null);
        setFeedback({
          open: true,
          type: "success",
          message: "The file was download succesfully",
          onClose: () => {
            setFeedback({ open: false });
          },
        });
      },
      onError: (err) => {
        setItemDownloading(null);

        if (err?.response?.status === 500) {
          setFeedback({
            open: true,
            type: "error",
            message: "There was an error trying to download the file",
          });
        } else {
          const msg = err?.response?.data ? err.response.data : err.message;
          setFeedback({
            open: true,
            type: "error",
            message: msg,
          });
        }
      },
    }
  );

  const onDownloadButtonClick = (e, params) => {
    downloadReportMutation.mutate(params);
  };

  async function fetchFlashReports() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/report/flash`
    );
    return response;
  }

  const { data, isLoading, isError } = useQuery(
    ["flashReports"],
    fetchFlashReports
  );

  return (
    <Container maxWidth="xlg">
      <Grid container spacing={1.5} sx={{ height: "100%" }}>
        <Grid
          item
          xs={3}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img src={statsImg} alt="locations" width="100%" loading="lazy" />
        </Grid>
        <Grid item xs={8} md={8}>
          <h1>Flash Reports</h1>

          {isLoading ? (
            <h4>Loading...</h4>
          ) : !isError ? (
            <div style={{ height: "60vh", width: "100vh" }}>
              <DataGrid
                rows={data?.data}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                getRowId={(row) => row.id_execution}
              />
            </div>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
