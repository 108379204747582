import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Tooltip,
  Divider,
  Snackbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState, forwardRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import MuiAlert from "@mui/material/Alert";
import HttpService from "../services/HttpService";
import CircularProgress from "@mui/material/CircularProgress";
import PaidIcon from "@mui/icons-material/Paid";
import BusinessIcon from "@mui/icons-material/Business";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NumbersIcon from "@mui/icons-material/Numbers";
import ArticleIcon from "@mui/icons-material/Article";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserService from "../services/UserService";

export default function PaymentsDetail(props) {
  const columns = [
    { field: "id", headerName: "ID", width: 90, hidden: true },
    {
      field: "lineNumber",
      headerName: "Line#",
      width: 50,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.lineNumber}
          </Typography>
        );
      },
    },
    {
      field: "memberId",
      headerName: "Member Id",
      width: 110,
      editable: UserService.hasRole(["pf-confirm"]),
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.memberId}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: UserService.hasRole(["pf-confirm"]),
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.email}
          </Typography>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 90,
      align: "right",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return `${valueFormatted}`;
      },
    },
    {
      field: "fullName",
      headerName: "Name",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.fullName}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      editable: UserService.hasRole(["pf-confirm"]),
      renderCell: (params) => {
        return (
          <div>
            <Typography
              sx={{ display: "block", fontWeight: "bold", fontSize: 12, mb: 0 }}
              gutterBottom
            >
              {params.row.status}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "updateDate",
      headerName: "Update Date",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.updateDate}
          </Typography>
        );
      },
    },
    {
      field: "idDescriptor",
      headerName: "Descriptor",
      width: 240,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.idDescriptor}
          </Typography>
        );
      },
    },
    {
      field: "error",
      headerName: "Error",
      width: 300,

      renderCell: (params) => {
        return (
          <Tooltip title={params.row.error}>
            <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
              {params.row.error}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const queryClient = useQueryClient();

  const updatePaymentMutation = useMutation(
    (payload) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/payfile/${payload.idPayFile}/update`,
        payload
      );
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "The request was verified succesfully",
          onClose: () => {
            refetchPaymentsDetail();
            setFeedback({ open: false });
          },
        });
      },
      onError: (err) => {
        const msg = err?.response?.data ? err.response.data : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
          onClose: () => {
            refetchPaymentsDetail();
            setFeedback({ open: false });
          },
        });
      },
    }
  );

  const handleEditCellChange = (row, id, field, oldValue, newValue) => {
    updatePaymentMutation.mutate({
      idPayFile: row.idPayFile,
      record: row,
      field,
      oldValue,
      newValue,
    });
  };

  const { paymentInfo } = props;
  async function fetchPaymentsDetail() {
    var url = window.API_URL + `/payfile/details/` + paymentInfo.idPayFile;
    if (paymentInfo.payFileDetail)
      url = url + "?lineNumber=" + paymentInfo.payFileDetail.lineNumber;
    const response = await HttpService.getAxiosClient().get(url);
    return response;
  }

  const {
    data: dataPaymentsDetail,
    isError: isErrorPaymentsDetail,
    isFetching: isFetchingPaymentsDetail,
    refetch: refetchPaymentsDetail,
  } = useQuery({
    queryKey: ["paymentsDetail"],
    queryFn: fetchPaymentsDetail,
    refetchOnWindowFocus: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };
  const totalAmountSuccess =
    dataPaymentsDetail &&
    dataPaymentsDetail?.data
      .filter(
        (record) =>
          record.status === "LOAD_SUCCESS" ||
          record.status === "READY_TO_NOTIFY"
      )
      .reduce((sum, record) => sum + record.amount, 0);

  const totalCountSuccess =
    dataPaymentsDetail &&
    dataPaymentsDetail?.data
      .filter(
        (record) =>
          record.status === "LOAD_SUCCESS" ||
          record.status === "READY_TO_NOTIFY"
      )
      .reduce((sum, record) => sum + 1, 0);

  const formattedAmountSuccess =
    totalAmountSuccess &&
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(totalAmountSuccess);

  const pendingAmountSuccess =
    dataPaymentsDetail &&
    dataPaymentsDetail?.data
      .filter(
        (record) =>
          record.status !== "LOAD_SUCCESS" &&
          record.status !== "READY_TO_NOTIFY"
      )
      .reduce((sum, record) => sum + record.amount, 0);

  const totalCountPending =
    dataPaymentsDetail &&
    dataPaymentsDetail?.data
      .filter(
        (record) =>
          record.status !== "LOAD_SUCCESS" &&
          record.status !== "READY_TO_NOTIFY"
      )
      .reduce((sum, record) => sum + 1, 0);

  const formattedAmountPending =
    pendingAmountSuccess &&
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(pendingAmountSuccess);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: window.innerWidth - 50,
        },
      }}
    >
      <Box component="form" noValidate autoComplete="off">
        <DialogActions>
          <Grid container spacing={1.5} paddingLeft="1rem">
            <Grid item xs={0.7}>
              <Typography fontWeight="bold">Company</Typography>
            </Grid>
            <Grid item xs={0.6}>
              <Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BusinessIcon style={{ paddingRight: "5px" }} />
                  {paymentInfo.idEmployer}
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Button onClick={handleClose} color={"error"}>
            {isFetchingPaymentsDetail ? (
              <CircularProgress color="inherit" />
            ) : (
              "Close"
            )}
          </Button>
        </DialogActions>
        <DialogTitle>
          <Grid container spacing={1.5} paddingLeft="1rem">
            <Grid item xs={6}>
              <Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArticleIcon style={{ paddingRight: "5px" }} />
                  {paymentInfo && paymentInfo.description}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ width: window.innerWidth, flexShrink: 0 }}>
          {isFetchingPaymentsDetail ? (
            <CircularProgress color="inherit" />
          ) : !isErrorPaymentsDetail ? (
            <Fragment>
              <Fragment>
                <Grid
                  style={{
                    marginLeft: "20px",
                    marginRight: "10px",
                    marginTop: 0,
                  }}
                  container
                  spacing={1.5}
                >
                  <Grid container spacing={1.5}>
                    <Grid item xs={1}>
                      <Typography>Total Amount:</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PaidIcon style={{ paddingRight: "5px" }} />
                          {paymentInfo && paymentInfo.totalAmount}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>Total Records:</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <NumbersIcon style={{ paddingRight: "5px" }} />
                          {paymentInfo && paymentInfo.numberOfRecords}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={1.5}
                      style={{
                        //marginLeft: "10px",
                        marginRight: "10px",
                        marginTop: "10px",
                        width: "50%",
                        marginLeft: "20px",
                      }}
                    >
                      <Grid container spacing={1.5}>
                        <Grid item xs={2} />
                        <Grid item xs={1}>
                          <Typography fontSize="1rem">Success:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography fontSize="1rem">
                            {formattedAmountSuccess && formattedAmountSuccess}
                          </Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                          <Typography fontSize="1rem">#:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography fontSize="1rem">
                            {totalCountSuccess}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1.5}>
                        <Grid item xs={2} />
                        <Grid item xs={1}>
                          <Typography fontSize="1rem">Pending:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography fontSize="1rem">
                            {formattedAmountPending && formattedAmountPending}
                          </Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                          <Typography fontSize="1rem">#:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography fontSize="1rem">
                            {totalCountPending}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider style={{ margin: "15px" }} />
                </Grid>

                <div style={{ height: 650, width: window.innerWidth - 100 }}>
                  <DataGrid
                    columnVisibilityModel={{ id: false }}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rows={dataPaymentsDetail?.data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    onCellEditStop={(params, event) =>
                      handleEditCellChange(
                        params.row,
                        params.id,
                        params.field,
                        params.value,
                        event?.target?.value
                      )
                    }
                  />
                </div>

                <Snackbar
                  open={feedback.open}
                  autoHideDuration={6000}
                  onClose={
                    feedback.onClose ? feedback.onClose : handleFeedbackClose
                  }
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={
                      feedback.onClose ? feedback.onClose : handleFeedbackClose
                    }
                    severity={feedback.type}
                    sx={{ width: "100%" }}
                  >
                    {feedback.message}
                  </Alert>
                </Snackbar>
              </Fragment>
            </Fragment>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
}
